<template>
  <div class="data">
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <span class="ar">تعديل اعلان</span>
              <span class="en">Edit Advertisement</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add ads -->
    <Load v-if="loading"></Load>
    <div class="add-advertisment" v-else>
      <Load v-if="loadingAdd" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="container" v-if="success">
        <div class="not-found">
          <span><i class="far fa-check-circle"></i></span>
          <h3 class="ar">تم تعديل الإعلان بنجاح</h3>
          <h3 class="en">Advertisement was successfully Edit</h3>
        </div>
      </div>
      <div class="container" v-else>
        <div class="my-form">
          <span class="ar"
            >اضافة الصور للاعلان تدعم العرض وتزيد الطلب اضافة صور الاعلان (حد
            اقصى 10 صور)</span
          >
          <span class="en">
            Adding images to the advertisement supports the display and
            increases the demand. Adding images for the advertisement (limit Max
            10 photos)</span
          >
          <form>
            <div class="form-group">
              <div class="upload-block">
                <div class="upload_wrap">
                  <input
                    type="file"
                    class="form-control"
                    multiple
                    accept="image/*"
                    @change="uploadImage"
                  />
                </div>
                <div
                  v-for="(image, index) in cardData.imagesSrc"
                  :key="index"
                  class="upload_item"
                >
                  <span
                    class="upload_del"
                    @click="remmoveImage(image.id, index)"
                  >
                    x
                  </span>
                  <img :src="image.image" class="upload_img" />
                </div>
              </div>
            </div>
            <!-- first select -->
            <div class="form-group">
              <select
                class="custom-select diaa-select ar"
                @change="chooseType()"
                v-model="cardData.type"
              >
                <option value="">إختر قسم الإعلان</option>
                <option value="taswiq_eqara">موجود لتسويق العقار</option>
                <option value="bina_wataemir">موجود للبناء والتعمير</option>
                <option value="sianuh">موجود لخدمات وصيانة المنازل</option>
                <option value="Weddings and occasions"
                  >موجود للافراح والمناسبات والكتشات</option
                >
              </select>
              <select
                class="custom-select diaa-select en"
                @change="chooseType()"
                v-model="cardData.type"
              >
                <option value="">Choose advertisment part</option>
                <option value="taswiq_eqara"
                  >Mawgoud to market the property</option
                >
                <option value="bina_wataemir"
                  >Mawgoud for construction and reconstruction</option
                >
                <option value="sianuh"
                  >Mawgoud for home services and maintenance</option
                >
                <option value="Weddings and occasions"
                  >Mawgoud for weddings, events and chats</option
                >
              </select>
            </div>
            <!-- areas -->
            <div
              class="form-group"
              v-if="selectType.firstSelect || selectType.otherSelect"
            >
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.area_id"
                @change="getGoverment()"
              >
                <option value="">
                  إختر اسم المنطقة
                </option>
                <option v-for="area in areas" :key="area.id" :value="area.id">
                  {{ area.name }}
                </option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.area_id"
                @change="getGoverment()"
              >
                <option value="">
                  Choose the area name
                </option>
                <option v-for="area in areas" :key="area.id" :value="area.id">
                  {{ area.name }}
                </option>
              </select>
            </div>
            <!-- goverment -->
            <div
              class="form-group"
              v-if="selectType.firstSelect || selectType.otherSelect"
            >
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.goverment_id"
                @change="getCity()"
              >
                <option value="">إختر العاصمة الإدارية أو المحافظة</option>
                <option
                  v-for="goverment in goverments"
                  :key="goverment.id"
                  :value="goverment.id"
                >
                  {{ goverment.name }}
                </option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.goverment_id"
                @change="getCity()"
              >
                <option value=""
                  >Choose the administrative capital or province</option
                >
                <option
                  v-for="goverment in goverments"
                  :key="goverment.id"
                  :value="goverment.id"
                >
                  {{ goverment.name }}
                </option>
              </select>
            </div>
            <!-- city -->
            <div
              class="form-group"
              v-if="selectType.firstSelect || selectType.otherSelect"
            >
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.city_id"
                @change="getResidential()"
              >
                <option value="">إختر إسم العاصمة الإدارية أو المركز</option>
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.city_id"
                @change="getResidential()"
              >
                <option value=""
                  >Choose the name of the administrative capital or
                  center</option
                >
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
            </div>
            <!-- residential -->
            <div class="form-group" v-if="selectType.firstSelect">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.residential_id"
                @change="getBuilds()"
              >
                <option value="">إختر اسم الحي</option>
                <option
                  v-for="residential in residentials"
                  :key="residential.id"
                  :value="residential.id"
                >
                  {{ residential.name }}
                </option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.residential_id"
                @change="getBuilds()"
              >
                <option value="">Choose a neighborhood name</option>
                <option
                  v-for="residential in residentials"
                  :key="residential.id"
                  :value="residential.id"
                >
                  {{ residential.name }}
                </option>
              </select>
            </div>
            <!-- building -->
            <div class="form-group" v-if="selectType.firstSelect">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.building_id"
              >
                <option value="">إختر اسم العقار</option>
                <option
                  v-for="build in builds"
                  :key="build.id"
                  :value="build.id"
                >
                  {{ build.name }}
                </option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.building_id"
              >
                <option value="">Choose the property name</option>
                <option
                  v-for="build in builds"
                  :key="build.id"
                  :value="build.id"
                >
                  {{ build.name }}
                </option>
              </select>
            </div>
            <!-- type -->
            <div class="form-group" v-if="selectType.firstSelect">
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.rent_type"
              >
                <option value="">للبيع ام للإيجار</option>
                <option value="sale">
                  بيع
                </option>
                <option value="rent">
                  إيجار
                </option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.rent_type"
              >
                <option value="">For sale or for rent</option>
                <option value="sale">
                  sale
                </option>
                <option value="rent">
                  rent
                </option>
              </select>
            </div>
            <!-- services -->
            <div class="form-group" v-if="selectType.otherSelect">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.service_id"
              >
                <option value="">إختر الخدمة</option>
                <option
                  v-for="service in services"
                  :key="service.id"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.service_id"
              >
                <option value="">Choose the service</option>
                <option
                  v-for="service in services"
                  :key="service.id"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>
            <!-- ---------------------- -->
            <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="عنوان الاعلان"
                v-model="cardData.subject"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="Advertisment name"
                v-model="cardData.subject"
              />
            </div>
            <div class="form-group">
              <h4>وصف الإعلان</h4>
              <ckeditor
                class="form-control ar"
                tag-name="textarea"
                placeholder="وصف الاعلان"
                v-model="cardData.description"
                :config="editorConfigAr"
              ></ckeditor>
              <ckeditor
                class="form-control en"
                tag-name="textarea"
                placeholder=" Advertisment description "
                v-model="cardData.description"
                :config="editorConfigEn"
              ></ckeditor>
            </div>
            <!-- <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="الموقع على الخريطة"
                id="autocomplete"
                v-model="cardData.address"
                @click="getStreetAddressFrom"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="Location on the map"
                id="autocomplete"
                v-model="cardData.address"
                @click="getStreetAddressFrom"
              />
            </div> -->
            <div class="form-group">
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <input
                    type="text"
                    class="form-control ar"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="رقم الجوال للتواصل"
                    v-model="cardData.phone_num"
                    lang="en"
                  />
                  <input
                    type="text"
                    class="form-control en"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Mobile number to contact"
                    v-model="cardData.phone_num"
                    lang="en"
                  />
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="site-treaty ar" style="padding: 0">
                    <div class="treaty" style="padding: 0">
                      <div class="ckbox">
                        <input
                          type="checkbox"
                          v-model="cardData.usePhone"
                          id="userPhone"
                          name="d"
                          @change="usePhoneClick"
                        />
                        <label for="usePhone">أرغب في إخفاء رقم الجوال</label>
                      </div>
                    </div>
                  </div>
                  <div class="site-treaty en" style="padding: 0">
                    <div class="treaty" style="padding: 0">
                      <div class="ckbox">
                        <input
                          type="checkbox"
                          v-model="cardData.usePhone"
                          id="usePhone"
                          name="d"
                          @change="usePhoneClick"
                        />
                        <label for="usePhone">Want to hide phone number </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="رقم الواتس للتواصل"
                v-model="cardData.phone_whats"
                lang="en"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="WhatsApp number for contact"
                v-model="cardData.phone_whats"
                lang="en"
              />
            </div> -->
            <div class="treaty">
              <ul class="list-inline ar">
                <li class="ckbox">
                  <input
                    @change="!allData.check3"
                    type="checkbox"
                    v-model="allData.check3"
                    id="radio3"
                    name="d"
                  />
                  <label for="radio3" style="font-size: 20px" class="mr-2"
                    >هل تريد تثبيت إعلاناتك ؟</label
                  >
                </li>
              </ul>
              <ul class="list-inline en">
                <li class="ckbox">
                  <input
                    @change="!allData.check1"
                    type="checkbox"
                    v-model="allData.check1"
                    id="radio"
                    name="d"
                  />
                  <label for="radio"
                    >I pledge before God to pay the application commission
                    described above in the event that the sale of the property
                    is carried out through the application, and God is what I
                    say is a martyr</label
                  >
                </li>
                <li class="ckbox">
                  <input
                    @change="!allData.check2"
                    type="checkbox"
                    v-model="allData.check2"
                    id="radio2"
                    name="d"
                  />
                  <label for="radio2"
                    >I pledge to pay the commission within a maximum period of
                    one week from the date of sale or rent</label
                  >
                </li>
                <li class="ckbox">
                  <input
                    @change="!allData.check3"
                    type="checkbox"
                    v-model="allData.check3"
                    id="radio3"
                    name="d"
                  />
                  <label for="radio3">Do you want pend this ads ?</label>
                </li>
              </ul>
              <!-- <PinShow @pay="pay" v-if="allData.check3"></PinShow> -->
            </div>
            <button
              type="button"
              class="btn form-btn btn-block ar"
              @click="updateAdvertisment"
            >
              تعديل الاعلان
            </button>
            <button
              type="button"
              class="btn form-btn btn-block en"
              @click="updateAdvertisment"
            >
              Edit Advertisment
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDx--BILIM2LjZBxVMjGYVb8YqrY-Vk_Yk"></script>
<script>
import axios from "axios";
import Load from "./../loading";
export default {
  data() {
    return {
      editorConfigAr: {
        contentsLangDirection: "rtl",
      },
      editorConfigEn: {
        contentsLangDirection: "ltr",
      },
      // get  data
      id: this.$route.params.id,
      // post data
      errorMessage: "",
      errorShow: false,
      errorColor: "",
      type: "",
      selectType: {
        firstSelect: false,
        otherSelect: false,
      },
      loading: false,
      loadingAdd: false,
      loadingSelect: false,
      success: false,
      baseURL: "http://mawjoud.sa/mogood/public/api",
      // areas
      areas: [],
      // goverment
      goverments: [],
      // city
      cities: [],
      // residential
      residentials: [],
      // build
      builds: [],
      //   services
      services: [],
      cardData: {
        type: "",
        area_id: "",
        goverment_id: "",
        city_id: "",
        residential_id: "",
        building_id: "",
        rent_type: "",
        service_id: "",
        _method: "PUT",
        // ----------
        subject: "",
        description: "",
        address: "",
        // latitude: "",
        // longitude: "",
        phone_num: "",
        usePhone: false,
        // phone_whats: "",
        image: [],
        imagesSrc: [],
        
      },
      allData: {
          check3: false,
        },
    };
  },
  methods: {
    // get data
    getDetails() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/adver/show?id=" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.card = res.data.data;
          this.cardData.imagesSrc = this.card.image;
          this.cardData.type = this.card.type;
          if (this.cardData.type == "taswiq_eqara") {
            this.selectType.firstSelect = true;
            this.selectType.otherSelect = false;
            this.cardData.rent_type = this.card.rent_type;
            this.getCity();
            this.cardData.city_id = this.card.city.id;
            this.getResidential();
            this.cardData.residential_id = this.card.residential.id;
            this.getBuilds();
            this.cardData.building_id = this.card.building.id;
          } else {
            this.selectType.firstSelect = false;
            this.selectType.otherSelect = true;
            this.getServices();
            this.cardData.service_id = this.card.service.id;
          }
          this.cardData.area_id = this.card.area.id;
          this.getGoverment();
          this.cardData.goverment_id = this.card.goverment.id;
          this.getCity();
          this.cardData.city_id = this.card.city.id;
          this.cardData.subject = this.card.subject;
          this.cardData.description = this.card.description;
          this.cardData.address = this.card.address;
          // this.cardData.latitude = this.card.latitude;
          // this.cardData.longitude = this.card.longitude;
          this.cardData.phone_num = this.card.phone_num;
          if (this.card.phone_num == null) {
            this.cardData.usePhone = true;
          } else {
            this.cardData.usePhone = false;
          }
          // this.cardData.phone_whats = this.card.phone_whats;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    // upload image
    uploadImage(e) {
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        let img = {
          image: URL.createObjectURL(selectedFiles[i]),
          file: selectedFiles[i],
        };
        this.cardData.imagesSrc.push(img);
        this.cardData.image = this.cardData.imagesSrc;
      }
    },
    remmoveImage(image_id, index) {
      this.cardData.imagesSrc.splice(index, 1);
      axios
        .post(this.baseURL + "/advertising/" + image_id + "/deleteimage", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then(() => {})
        .catch((error) => {
          console.log(error.response);
        });
    },
    chooseType() {
      if (this.cardData.type == "taswiq_eqara") {
        this.selectType.firstSelect = true;
        this.selectType.otherSelect = false;
      } else if (
        this.cardData.type == "bina_wataemir" ||
        this.cardData.type == "sianuh" ||
        this.cardData.type == "Weddings and occasions"
      ) {
        this.selectType.firstSelect = false;
        this.selectType.otherSelect = true;
      } else {
        this.selectType.firstSelect = false;
        this.selectType.otherSelect = false;
      }
    },
    // areas
    getAreas() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/getarea", {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.areas = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    // goverment
    getGoverment() {
      this.loadingSelect = true;
      axios
        .get(this.baseURL + "/getgoverment?area_id=" + this.cardData.area_id, {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loadingSelect = false;
          this.goverments = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // city
    getCity() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL + "/getcity?goverment_id=" + this.cardData.goverment_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.cities = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // residential
    getResidential() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL + "/getresidential?city_id=" + this.cardData.city_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.residentials = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // builds
    getBuilds() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL +
            "/getbuilding?residential_id=" +
            this.cardData.residential_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.builds = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // services
    getServices() {
      this.loadingSelect = true;
      axios
        .get(this.baseURL + "/getservice", {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loadingSelect = false;
          this.services = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // ------------- get location from map ---------------------
    // getStreetAddressFrom() {
    //   var self = this;
    //   var input = document.getElementById("autocomplete");
    //   var searchBox = new google.maps.places.SearchBox(input);
    //   searchBox.addListener("places_changed", function() {
    //     var places = searchBox.getPlaces();
    //     if (places.length == 0) {
    //       return;
    //     }
    //     var bounds = new google.maps.LatLngBounds();
    //     places.forEach(function(place) {
    //       bounds.extend(place.geometry.location);
    //       place.geometry.location.lng();
    //       place.geometry.location.lat();
    //       place.formatted_address;
    //       self.cardData.longitude = place.geometry.location.lng();
    //       self.cardData.latitude = place.geometry.location.lat();
    //       self.cardData.address = place.formatted_address;
    //     });
    //   });
    // },
    usePhoneClick() {
      var phoneNumber = document.getElementById("phoneNumber");
      if (this.cardData.usePhone == true) {
        phoneNumber.classList.add("disabled");
      } else {
        phoneNumber.classList.remove("disabled");
      }
    },
    // -----------------------
    updateAdvertisment() {
      const data = new FormData();
      var images = this.cardData.image;
      images.forEach((item) => {
        images = item.file;
        data.append("image[]", images);
      });
      data.append("_method", this.cardData._method);
      data.append("type", this.cardData.type);
      data.append("area_id", this.cardData.area_id);
      data.append("goverment_id", this.cardData.goverment_id);
      data.append("city_id", this.cardData.city_id);
      data.append("residential_id", this.cardData.residential_id);
      data.append("building_id", this.cardData.building_id);
      data.append("rent_type", this.cardData.rent_type);
      data.append("service_id", this.cardData.service_id);
      data.append("subject", this.cardData.subject);
      data.append("description", this.cardData.description);
      if (this.cardData.phone_num != null) {
        data.append("phone_num", this.cardData.phone_num);
      } else {
        data.append("phone_num", "");
      }
      if (this.cardData.usePhone == true) {
        data.append("phone_num", "");
      } else {
        data.append("phone_num", this.cardData.phone_num);
      }
      // data.append("phone_whats", this.cardData.phone_whats);
      // data.append("latitude", this.cardData.latitude);
      // data.append("longitude", this.cardData.longitude);
      data.append("address", this.cardData.address);
      this.loadingAdd = true;
      axios
        .post(this.baseURL + "/advertising/" + this.id + "/update", data, {
          headers: {
            lang: localStorage.getItem("lang"),
            Authorization: "bearer" + localStorage.getItem("token"),
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loadingAdd = false;
          if (res.status == 200) {
            this.success = true;
            if (this.allData.check3 == true) {
              this.$router.push("/pin-show/" + this.$route.params.id);
            }
          }
        })
        .catch((error) => {
          this.loadingAdd = false;
          if (error.response.status == 422) {
            this.errorColor = "faild";
            this.errorShow = true;
            this.errorMessage = error.response.data.errors;
          }
        })
        .finally(() => (this.loadingAdd = false));
    },
  },

  mounted() {
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({name: "login"});
    }
    this.getDetails();
    this.getAreas();
    this.getServices();
  },
  name: "add-show",
  components: {
    Load,
  },
};
</script>
