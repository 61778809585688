<template>
  <div class="main">
    <!-- title -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <p class="ar">اعلاناتي</p>
              <p class="en">my advertisement</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Load v-if="loading"></Load>
    <!--start favourite-->
    <div class="favourite" v-else>
      <div class="container">
        <div class="not-found" v-if="cards == ''">
          <span><i class="fas fa-exclamation-triangle"></i> </span>
          <h3 class="ar">لا يوجد اعلانات</h3>
          <h3 class="en">There are no advertisement</h3>
        </div>
        <div class="favourite-card" v-else>
          <Load v-if="loadingDelete" class="load-form"></Load>
          <div
            v-for="card in cards"
            :key="card.index"
            class="offer-section fadeInUp"
          >
            <div class="offer-details">
              <router-link :to="'/datails/' + card.id" tag="a">
                <h5>{{ card.subject }}</h5>
              </router-link>
              <div class="flex-div">
                <div class="address-det">
                  <span class="icon">
                    <i class="fas fa-map-marker-alt"></i
                  ></span>
                  <span> {{ card.area }} </span>
                </div>
                <div class="post-date">
                  <span class="ar">
                    منذ
                    {{ card.created_at.date | moment("from", "now", true) }}
                  </span>
                  <span class="en">
                    {{ card.created_at.date | moment("from", "now", true) }}
                    ago
                  </span>
                </div>
              </div>
              <div class="flex-div">
                <div class="owner-name">
                  <span class="icon"><i class="fas fa-user"></i></span>
                  <span> {{ card.user }} </span>
                </div>
                <div class="owner-num">
                  <span>{{ card.id }} </span>
                </div>
              </div>
            </div>
            <div class="offer-image" v-if="card.image !== null">
              <div class="image">
                <img :src="card.image" />
              </div>
            </div>
            <div class="offer-image" v-else>
              <div class="image">
                <img
                  src="../../assets/images/icons/hidden-image.png"
                  class="load-image"
                />
              </div>
            </div>
            <div class="editing ar">
              <!-- <button @click="showModal" type="button" class="delete">
                تمديد تثبيت الإعلان
              </button>
              <button
                type="button"
                class="delete"
                @click="updateAds(card.subject)"
              >
                تحديث
              </button> -->
              <router-link
                :to="'/edit/' + card.id"
                tag="a"
                type="button"
                class="edit"
              >
                تعديل
              </router-link>
              <button type="button" class="delete" @click="deleteAds(card.id)">
                حذف
              </button>
            </div>
            <div class="editing en">
              <button type="button" class="delete">ad install extension</button>
              <button
                type="button"
                class="delete"
                @click="updateAds(card.subject)"
              >
                Update
              </button>
              <router-link
                :to="'/edit/' + card.id"
                tag="a"
                type="button"
                class="edit"
              >
                Edit
              </router-link>
              <button type="button" class="delete" @click="deleteAds(card.id)">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- alert -->
    <transition name="fade">
      <div class="all-alert" v-if="alert.alertShow">
        <div class="alert-section">
          <span>
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <h3 class="ar">هل انت متأكد من حذف الإعلان</h3>
          <h3 class="en">are you sure to delete this ads ?</h3>
          <ul>
            <li>
              <button type="button" class="delete ar" @click="deleteAlert">
                حذف
              </button>
              <button type="button" class="delete en" @click="deleteAlert">
                delete
              </button>
            </li>
            <li>
              <button
                type="button"
                class="back ar"
                @click="alert.alertShow = false"
              >
                تراجع
              </button>
              <button
                type="button"
                class="back en"
                @click="alert.alertShow = false"
              >
                back
              </button>
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="error-message error-message-new ar" v-if="errorShow">
        <span @click="errorShow = false" class="icon"
          ><i class="fas fa-times"></i
        ></span>
        <p class="success ar">تم تحديث إعلان {{ adsUpdateName }} بنجاح</p>
        <p class="success en">
          The ads {{ adsUpdateName }} update successfully
        </p>
      </div>
    </transition>
    <MyModal @closeModel="closeModel" :show="showMod">
      <template #default>
        <PinShow @hideModel="closeModel"></PinShow>
      </template>
    </MyModal>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
import MyModal from "./../Modal/BaseModal.vue";
import PinShow from "../show/pin-show.vue";
export default {
  name: "Favourite",
  data() {
    return {
      showMod: false,
      baseURL: "http://mawjoud.sa/mogood/public/api",
      loading: false,
      loadingDelete: false,
      errorMessage: "",
      errorShow: false,
      errorColor: "",
      cards: [],
      card_id: "",
      alert: {
        alertShow: false,
      },
      adsUpdateName: "",
    };
  },

  methods: {
    closeModel() {
      this.showMod = false;
    },
    showModal() {
      this.showMod = true;
    },
    myAds() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/getadvertising", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.cards = res.data.data;
          console.log(this.cards);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    updateAds(name) {
      this.errorShow = true;
      this.adsUpdateName = name;
    },
    deleteAds(id) {
      this.card_id = id;
      this.alert.alertShow = true;
    },
    deleteAlert() {
      this.alert.alertShow = false;
      this.loadingDelete = true;
      axios
        .post(this.baseURL + "/advertising/" + this.card_id + "/delete", {
          headers: {
            Authorization: "bearer " + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then(() => {
          this.loadingDelete = false;
          this.myAds();
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => (this.loadingDelete = false));
    },
  },
  mounted() {
    this.myAds();
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  components: {
    Load,
    MyModal,
    PinShow,
  },
};
</script>
