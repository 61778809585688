<template>
  <div class="data">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="gray-green">
              <span class="ar">الشروط و الأحكام</span>
              <span class="en"> Terms and conditions </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <Load v-if="loading"></Load>
    <div class="usage-policy" v-else>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="all-con">
              <p v-html="text.value">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  data() {
    return {
      loading: false,
      baseURL: "http://mawjoud.sa/mogood/public/api",
      text: "",
    };
  },
  mounted() {
    this.getText();
  },
  methods: {
    getText() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/setting/roules",
        {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.text = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
  },
  name: "About",
  components: {
    Load,
  },
};
</script>
