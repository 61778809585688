<template>
  <div class="main">
    <!-- title -->
    <div class="green-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-green">
              <span>{{ card.subject }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Load v-if="loading"></Load>
    <!-- details -->
    <div class="product" v-else>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="top-desc">
              <p v-html="card.description"></p>
            </div>
            <div class="address-details">
              <div class="city">
                <span class="icon"
                  ><i class="fas fa-map-marker-alt"></i> {{ area.name }}</span
                >
                <span class="ar">المحافظة : {{ goverment.name }}</span>
                <span class="en">governorate : {{ goverment.name }}</span>
                <span class="ar">المركز : {{ city.name }}</span>
                <span class="en">station : {{ city.name }}</span>
              </div>
              <div class="user">
                <span class="icon"
                  ><i class="fas fa-user"></i> {{ user.name }}
                </span>
                <span>{{ card.id }}</span>
                <span>{{ time.date | moment("from", "now") }}</span>
              </div>
            </div>
            <!-- slider -->
            <carousel
              class="slider"
              :autoplay="true"
              :nav="false"
              :loop="true"
              :items="1"
              :mouseDrag="true"
            >
              <img
                v-for="image in sliderImages"
                :key="image.id"
                :src="image.image"
                @click="imgPop(image.image)"
              />
            </carousel>
            <!-- end of slider -->

            <!-- map -->
            <!-- npm install vue2-google-maps  -->
            <!-- <div class="large-map">
              <GmapMap :center="mapPosition" :zoom="7" map-type-id="terrain">
                <GmapMarker
                  :position="mapPosition"
                  :clickable="true"
                  :draggable="true"
                  :icon="{
                    url: require('../../assets/images/icons/location-client.png'),
                  }"
                />
                />
              </GmapMap>
            </div> -->
            <!-- end of map -->
            <div class="social-info">
              <ul class="right-info list-unstyled">
                <li v-if="card.phone_num != null">
                  <span class="icon"><i class="fas fa-phone"></i></span>
                  <a :href="'tel:+966' + card.phone_num" target="_blank">
                    {{ card.phone_num }}
                  </a>
                </li>
                <li v-else>
                  <a :href="'mailto:' + user.email" target="_blank">
                    <span class="ar">يمكنك التواصل برسالة خاصة </span>
                    <span class="en"> You can chat </span>
                  </a>
                </li>
              </ul>
              <ul class="center-info list-unstyled">
                <li v-if="token">
                  <a
                    :href="
                      '//api.whatsapp.com/send?phone=+966' +
                      card.phone_num +
                      '&text=' +
                      pageLocation +
                      ' ' +
                      card.subject
                    "
                    target="_blank"
                  >
                    <span class="icon"><i class="fab fa-whatsapp"></i></span>
                    <!-- <span class="ar"> التواصل عبر الوتساب </span>
                    <span class="en"> Communication via WhatsApp </span> -->
                  </a>
                </li>
                <li class="fav" :class="{ active: isActive }">
                  <button type="button" @click="likeMethod()">
                    <!-- <span class="ar"> مفضلة </span>
                    <span class="en"> Favourite </span> -->
                  </button>
                </li>
                <li>
                  <button type="button" @click="iconSocial">
                    <span class="icon"><i class="fas fa-share-alt"></i></span>
                    <!-- <span class="ar"> مشاركة </span>
                    <span class="en"> Share </span> -->
                  </button>
                  <div class="social-icon" :class="{ active: showSocial }">
                    <ul>
                      <li>
                        <ShareNetwork
                          network="facebook"
                          :url="pageLocation"
                          :title="card.subject"
                          :description="card.description"
                          :quote="card.description"
                          hashtags="موجود"
                        >
                          <span class="socialIcon">
                            <i class="fab fa-facebook-square"></i>
                          </span>
                        </ShareNetwork>
                      </li>
                      <li>
                        <ShareNetwork
                          network="twitter"
                          :url="pageLocation"
                          :title="card.subject"
                          :description="card.description"
                          :quote="card.description"
                          hashtags="موجود"
                        >
                          <span class="socialIcon">
                            <i class="fab fa-twitter-square"></i>
                          </span>
                        </ShareNetwork>
                      </li>
                      <li>
                        <ShareNetwork
                          network="whatsApp"
                          :url="pageLocation"
                          :title="card.subject"
                          :description="card.description"
                          :quote="card.description"
                          hashtags="موجود"
                        >
                          <span class="socialIcon">
                            <i class="fab fa-whatsapp-square"></i>
                          </span>
                        </ShareNetwork>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <ul class="left-info list-unstyled">
                <li>
                  <span class="icon"><i class="fas fa-envelope"></i></span>
                  <button @click="openChat">
                    <span class="ar"> رسالة خاصة </span>
                    <span class="en"> Chat </span>
                  </button>
                </li>
                <li>
                  <router-link :to="'/' + id + '/report'" tag="a">
                    <span class="icon"><i class="fas fa-flag"></i></span>
                    <span class="ar"> ابلاغ </span>
                    <span class="en"> Reporting </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="img-popup" v-if="popup">
        <div class="image">
          <img :src="imgSrc" />
          <span class="icon" @click="popup = false">
            <i class="fas fa-times"></i>
          </span>
        </div>
        <div class="overlay" @click="popup = false"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
// slider
import carousel from "vue-owl-carousel2";
export default {
  name: "CardDetails",
  data() {
    return {
      baseURL: "http://mawjoud.sa/mogood/public/api",
      id: this.$route.params.id,
      loading: false,
      card: "",
      area: "",
      goverment: "",
      city: "",
      user: "",
      // time
      time: "",
      // slider
      sliderImages: [],
      // map
      // mapPosition: {
      //   lat: null,
      //   lng: null,
      // },
      isActive: "",
      favourit: "",
      advertising_id: "",
      pageLocation: window.location.href,
      showSocial: false,
      // popup
      popup: false,
      imgSrc: "",
      token: localStorage.getItem("token"),
    };
  },

  methods: {
    cardDetails() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/adver/show?id=" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.card = res.data.data;
          this.area = this.card.area;
          this.goverment = this.card.goverment;
          this.city = this.card.city;
          this.user = this.card.user;
          this.time = this.card.created_at;
          this.sliderImages = this.card.image;
          // this.mapPosition.lat = parseFloat(this.card.latitude);
          // this.mapPosition.lng = parseFloat(this.card.longitude);
          // like
          this.advertising_id = this.card.id;
          this.favourit = this.card.favourit;
          if (this.favourit == "false") {
            this.isActive = false;
          } else {
            this.isActive = true;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    // like method
    likeMethod() {
      if (!localStorage.getItem("token")) {
        this.$router.push({ name: "login" });
      } else {
        this.isActive = !this.isActive;
        axios
          .post(
            this.baseURL + "/addfavourit",
            { advertising_id: this.advertising_id },
            {
              headers: {
                Authorization: "bearer" + localStorage.getItem("token"),
                "cache-control": "no-cache",
                "Content-type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error.response);
          })
          .finally();
      }
    },
    openChat() {
      const data = new FormData();
      data.append("provider_id", this.card.user.id);
      axios
        .post(this.baseURL + "/newchat", data, {
          headers: {
            lang: localStorage.getItem("lang"),
            Authorization: "bearer" + localStorage.getItem("token"),
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.$router.push({ path: "/chat/" + res.data.data.id });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    iconSocial() {
      this.showSocial = !this.showSocial;
    },
    imgPop(src) {
      this.popup = true;
      this.imgSrc = src;
    },
  },
  mounted() {
    this.cardDetails();
  },
  components: {
    Load,
    // slider
    carousel,
  },
};
</script>
