<template>
  <div class="data">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <div class="login">
      <Load v-if="loading" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="error-message" v-if="errorpasswordShow">
        <p :class="errorColor">
          {{ errorpassword_confirmation }}
        </p>
      </div>
      <div class="container">
        <div class="have-account">
          <span class="ar">من فضلك قم بإدخال كلمة المرور الجديدة</span>
          <span class="en">Please enter your new password </span>
        </div>
        <div class="my-form">
          <form method="get" action="index.html">
            <div class="form-group">
              <input
                :type="visibility"
                class="form-control ar"
                placeholder="كلمة المرور الجديدة"
                v-model="user.new_password"
              />
              <input
                :type="visibility"
                class="form-control en"
                placeholder="new password"
                v-model="user.new_password"
              />
              <span class="icon"><i class="fas fa-lock"></i></span>
            </div>
            <div class="form-group">
              <input
                :type="visibility"
                class="form-control ar"
                placeholder="تأكيد كلمة المرور الجديدة"
                v-model="user.confirm_password"
              />
              <input
                :type="visibility"
                class="form-control en"
                placeholder="confirm new password"
                v-model="user.confirm_password"
              />
              <span class="icon"><i class="fas fa-lock"></i></span>
            </div>
            <div class="ckbox show-password">
              <input
                type="checkbox"
                id="radio"
                name="d"
                @change="showpassword"
              />
              <label for="radio" class="ar"> إظهار كلمة المرور </label>
              <label for="radio" class="en"> Show Password </label>
            </div>
            <button
              type="button"
              class="btn form-btn btn-block"
              @click="forgetPassword()"
            >
              <span class="ar"> متابعة </span>
              <span class="en"> Next </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Load from "./../loading";
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      errorMessage: "",
      errorpassword_confirmation: "",
      errorShow: false,
      errorpasswordShow: false,
      errorColor: "",
      visibility: "password",
      baseURL: "http://mawjoud.sa/mogood/public/api",
      user: {
        new_password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    forgetPassword() {
      this.loading = true;
      if (this.user.confirm_password !== this.user.new_password) {
        this.loading = false;
        this.errorColor = "faild";
        this.errorpasswordShow = true;
        this.errorpassword_confirmation =
          "الرقم السري و تأكيد الرقم السري مختلفين";
      } else {
        axios
          .post(this.baseURL + "/reset_password", this.user, {
            headers: {
              Authorization:
                "Bearer" + localStorage.getItem("mawgoud_forget_token"),
              lang: localStorage.getItem("lang"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.status == 200) {
              this.errorpasswordShow = false;
              this.errorColor = "success";
              this.errorShow = true;
              this.errorMessage = "تم تعديل كلمة المرور بنجاح";
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, 1000);
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response);
            if (error.response.status == 400) {
              this.errorColor = "faild";
              this.errorShow = true;
              this.errorMessage = error.response.data.message;
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    showpassword() {
      if (this.visibility === "text") {
        this.visibility = "password";
      } else {
        this.visibility = "text";
      }
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    if (token) {
      this.$router.push({ name: "mainhome" });
    }
  },
  name: "forget-enter-code",
  components: {
    Load,
  },
};
</script>
