<template>
  <div class="data">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <div class="login">
      <Load v-if="loading" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="error-message" v-if="errorpasswordShow">
        <p :class="errorColor">
          {{ errorpassword_confirmation }}
        </p>
      </div>
      <div class="container">
        <div class="my-form">
          <form action="index.html">
            <!-- <div class="form-group">
              <div class="upload-block">
                <div class="upload_wrap">
                  <img :src="user.imgSrc" class="uploading-image" />
                  <input type="file" accept="image/*" @change="uploadImage" />
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="اسم المستخدم"
                v-model="user.name"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="user name"
                v-model="user.name"
              />
              <span class="icon"><i class="fas fa-user"></i></span>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="رقم الجوال"
                v-model="user.phone"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="phone number"
                v-model="user.phone"
              />
              <span class="icon"><i class="fas fa-phone"></i></span>
            </div>
            <!-- <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder=" رمز المستخدم"
                v-model="user.code"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="User Code"
                v-model="user.code"
              />
              <span class="icon"><i class="fas fa-edit"></i></span>
            </div> -->
            <!-- <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="البريد الإلكتروني"
                v-model="user.email"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="email"
                v-model="user.email"
              />
              <span class="icon"><i class="fas fa-envelope"></i></span>
            </div> -->
            <div class="form-group">
              <input
                type="password"
                class="form-control ar"
                placeholder="كلمة المرور"
                v-model="user.password"
              />
              <input
                type="password"
                class="form-control en"
                placeholder="password"
                v-model="user.password"
              />
              <span class="icon"><i class="fas fa-lock"></i></span>
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control ar"
                placeholder="تأكيد كلمة المرور"
                v-model="user.password_confirmation"
              />
              <input
                type="password"
                class="form-control en"
                placeholder="confirm password"
                v-model="user.password_confirmation"
              />
              <span class="icon"><i class="fas fa-lock"></i></span>
            </div>
            <button
              type="button"
              class="btn form-btn btn-block"
              @click="registerNow()"
            >
              <span class="ar"> تسجيل </span>
              <span class="en"> register </span>
            </button>
          </form>
          <div class="have-account">
            <span class="ar"
              >بالفعل امتلك حساب
              <router-link tag="a" to="/login">تسجيل دخول</router-link>
            </span>
            <span class="en"
              >already have account ?
              <router-link tag="a" to="/login">login</router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Load from "./../loading";
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      errorMessage: "",
      errorpassword_confirmation: "",
      errorShow: false,
      errorpasswordShow: false,
      errorColor: "",
      baseURL: "http://mawjoud.sa/mogood/public/api",
      user: {
        // image: null,
        // imgSrc: null,
        name: "",
        phone: "",
        // email: "",
        code: "",
        password: "",
        password_confirmation: "",
        device_token: "device_token",
        device_type: "device_type",
      },
    };
  },
  methods: {
    // uploadImage(event) {
    //   this.user.image = event.target.files[0];
    //   this.user.imgSrc = URL.createObjectURL(this.user.image);
    // },
    registerNow() {
      const data = new FormData();
      // data.append("image", this.user.image);
      data.append("name", this.user.name);
      data.append("phone", this.user.phone);
      data.append("code", this.user.code);
      // data.append("email", this.user.email);
      data.append("password", this.user.password);
      data.append("password_confirmation", this.user.password_confirmation);
      this.loading = true;
      // confirm password
      if (this.user.password_confirmation !== this.user.password) {
        this.loading = false;
        this.errorColor = "faild";
        this.errorpasswordShow = true;
        this.errorpassword_confirmation =
          "الرقم السري و تأكيد الرقم السري مختلفين";
      } else {
        this.errorpasswordShow = false;
        axios
          .post(this.baseURL + "/register", data, {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.status == 200) {
              this.errorColor = "success";
              this.errorShow = true;
              this.errorMessage = "تم تسجيل حسابك بنجاح";
            }
            this.$router.push({ name: "login" });
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response);
            if (error.response.status == 400) {
              this.errorColor = "faild";
              this.errorShow = true;
              this.errorMessage = error.response.data.message;
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    if (token) {
      this.$router.push({ name: "mainhome" });
    }
  },
  name: "login",
  components: {
    Load,
  },
};
</script>
