<template>
  <div class="main">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="thank-you">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="thank-div">
              <div class="check">
                <svg viewBox="-10 -10 500 500">
                  <path
                    class="circle"
                    d="M877.28,335.72a203.17,203.17,0,0,1,37.86,118.1C915.14,565.26,823.44,657,712,657s-203.14-91.7-203.14-203.14S600.56,250.68,712,250.68a203.21,203.21,0,0,1,144.67,60.53"
                    transform="translate(-508.86 -250.68)"
                  />
                  <polyline
                    class="check"
                    points="78.54 229.94 179.32 300.74 347.98 60.67"
                  />
                </svg>
              </div>
              <h4 class="ar">تمت عملية الدفع بنجاح</h4>
              <h4 class="en">the payment method has been successfully</h4>
              <div class="cent-align">
                <router-link class="ar" to="/"
                  >العودة للصفحة الرئيسية</router-link
                >
                <router-link class="en" to="/">Back To Home</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      baseURL: "http://mawjoud.sa/mogood/public/api",
      paymentData: {
        ad_id: "",
        package_id: "",
        transaction_id: "",
      },
      success: false,
    };
  },
  methods: {
    addPackages() {
      if (this.paymentData.ad_id) {
        const data = new FormData();
        data.append("ad_id", this.paymentData.ad_id);
        data.append("typeadvertising_id", localStorage.getItem("packageId"));
        data.append("transaction_id", this.paymentData.transaction_id);
        axios
          .post(this.baseURL + "/advertising/transaction", data, {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
              lang: localStorage.getItem("lang"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
            },
          })
          .then(() => {
            this.success = true;
            localStorage.removeItem("packageId");
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        const data = new FormData();
        data.append("package_id", localStorage.getItem("packageId"));
        data.append("transaction_id", this.paymentData.transaction_id);
        axios
          .post(this.baseURL + "/userPackage/create", data, {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
              lang: localStorage.getItem("lang"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
            },
          })
          .then(() => {
            this.success = true;
            localStorage.removeItem("packageId");
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
  },
  components: {},
  mounted() {
    let params = new URLSearchParams(window.location.search);
    this.paymentData.ad_id = params.get('ad_id')
    this.paymentData.transaction_id = params.get("id");
    this.addPackages();
  },
};
</script>
