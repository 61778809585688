<template>
  <div class="data">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <div class="login">
      <Load v-if="loading" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="container">
        <div class="my-form">
          <form method="get" action="index.html">
            <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="رقم الجوال"
                v-model="user.identifier"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="Phone number"
                v-model="user.identifier"
              />
              <span class="icon"><i class="fas fa-phone"></i></span>
            </div>
            <div class="form-group">
              <input
                :type="visibility"
                class="form-control ar"
                placeholder="كلمة المرور"
                v-model="user.password"
              />
              <input
                :type="visibility"
                class="form-control en"
                placeholder="password"
                v-model="user.password"
              />
              <span class="icon"><i class="fas fa-lock"></i></span>
            </div>
            <div class="ckbox show-password">
              <input
                type="checkbox"
                id="radio"
                name="d"
                @change="showpassword"
              />
              <label for="radio" class="ar"> إظهار كلمة المرور </label>
              <label for="radio" class="en"> Show Password </label>
            </div>
            <button
              type="button"
              class="btn form-btn btn-block"
              @click="loginNow()"
            >
              <span class="ar"> دخول </span>
              <span class="en"> Login </span>
            </button>
          </form>
          <div class="have-account">
            <span class="ar">
              <router-link
                tag="a"
                to="/forget-enter-phone"
                style="color: #178083"
              >
                هل نسيت كلمة المرور ؟</router-link
              >
            </span>
            <span class="en">
              <router-link
                tag="a"
                to="/forget-enter-phone"
                style="color: #178083"
              >
                Did You Forget Your Password ?</router-link
              >
            </span>
          </div>
          <div class="have-account">
            <span class="ar"
              >ليس لديك حساب ؟
              <router-link tag="a" to="/register"> إنشاء حساب</router-link>
            </span>
            <span class="en"
              >don't have account ?
              <router-link tag="a" to="/register"> register</router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  data() {
    return {
      loading: false,
      errorMessage: "",
      errorShow: false,
      errorColor: "",
      visibility: "password",
      baseURL: "http://mawjoud.sa/mogood/public/api",
      user: {
        identifier: "",
        password: "",
        device_token: "device_token",
        device_type: "device_type",
      },
    };
  },
  methods: {
    loginNow() {
      this.loading = true;
      // confirm password
      axios
        .post(this.baseURL + "/login", this.user, {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.errorColor = "success";
          this.errorShow = true;
          this.errorMessage = "تم تسجيل دخول بنجاح";
          localStorage.setItem("mawjood-user", res.data.user.name);
          localStorage.setItem("token", res.data.token);
          this.$router.go(this.$router.push({ name: "mainhome" }));
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response);
          if (error.response.status == 400) {
            this.errorColor = "faild";
            this.errorShow = true;
            this.errorMessage = error.response.data.message;
          } else {
            this.errorColor = "faild";
            this.errorShow = true;
            this.errorMessage = "تسجيل دخول خطأ";
          }
        })
        .finally(() => (this.loading = false));
    },
    showpassword() {
      if (this.visibility === "text") {
        this.visibility = "password";
      } else {
        this.visibility = "text";
      }
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    if (token) {
      this.$router.push({ name: "mainhome" });
    }
  },
  name: "login",
  components: {
    Load,
  },
};
</script>
