<template>
  <div>
    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <div class="foot">
              <h4 class="ar">حمل التطبيق الان</h4>
              <h4 class="en">Download App Now</h4>
              <a href="https://play.google.com/store/apps/details?id=com.rmalsax.mawgood" target="_blank" class="app-download">
                <div class="link-txt">
                  <span class="ar">متاح على متجر</span>
                  <span class="en">Avaliable On</span>
                  <p>Google Play</p>
                </div>
                <i class="fab fa-google-play"></i>
              </a>
              <a href="" class="app-download">
                <div class="link-txt">
                  <span class="ar">متاح على متجر</span>
                  <span class="en">Avaliable On</span>
                  <p>App Store</p>
                </div>
                <i class="fab fa-apple"></i>
              </a>
            </div>
          </div>
          <div class="col-sm-4">
            <ul class="list-unstyled">
              <li>
                <router-link to="/commission" tag="a">
                  <span class="ar"> حساب و سداد العمولة </span>
                  <span class="en"> Calculation and payment of commission</span>
                </router-link>
              </li>
              <li>
                <router-link to="/usage-policy" tag="a">
                  <span class="ar"> سياسة الاستخدام </span>
                  <span class="en"> Usage Policy </span>
                </router-link>
              </li>
              <li>
                <router-link to="/terms" tag="a">
                  <span class="ar"> الشروط والاحكام </span>
                  <span class="en"> Terms and conditions </span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-4">
            <ul class="list-unstyled">
              <li>
                <router-link to="/about" tag="a">
                  <span class="ar"> عن الموقع </span>
                  <span class="en"> About Us</span>
                </router-link>
              </li>
              <li>
                <router-link to="/contact" tag="a">
                  <span class="ar"> تواصل معنا </span>
                  <span class="en"> Contact Us </span>
                </router-link>
              </li>
               <li class="img-master">
                <img src="./../../assets/images/icons/master.png"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="end-footer">
      <h3 class="ar">
        جميع الحقوق محفوظة <a href="#">لمؤسسة شركة موجود للتسويق الإلكتروني</a>©2021
      </h3>
      <h3 class="en">
        All Right Reserved <a href="#">for Mawgoud</a>©2021
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
