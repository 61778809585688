<template>
  <div class="main">
    <!-- title -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <span class="ar">الإعدادات</span>
              <span class="en">Settings</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--start settings-->
    <section class="setting">
      <div class="container">
        <ul class="list-unstyled">
          <li>
            <router-link tag="a" class="ar" to="/editProfile">
              <span class="icon"><i class="fas fa-user"></i></span>
              تعديل بياناتي
            </router-link>
            <router-link tag="a" class="en" to="/editProfile">
              <span class="icon"><i class="fas fa-user"></i></span>
              Edit Profile
            </router-link>
          </li>
          <li>
            <router-link tag="a" class="ar" to="/myAds">
              <span class="icon"><i class="far fa-edit"></i></span>
              اعلاناتي
            </router-link>
            <router-link tag="a" class="en" to="/myAds">
              <span class="icon"><i class="far fa-edit"></i></span>
              My Ads
            </router-link>
          </li>
          <li>
            <router-link tag="a" class="ar" to="/commission">
              <span class="icon"><i class="fas fa-calculator"></i> </span>
              حساب العمولة
            </router-link>
            <router-link tag="a" class="en" to="/commission">
              <span class="icon"><i class="fas fa-calculator"></i> </span>
              Commission account
            </router-link>
          </li>
          <li>
            <router-link tag="a" class="ar" to="/favourite">
              <span class="icon"> <i class="fas fa-heart"></i> </span>
              المفضلة
            </router-link>
            <router-link tag="a" class="en" to="/favourite">
              <span class="icon"> <i class="fas fa-heart"></i> </span>
              Favourite
            </router-link>
          </li>
          <li>
            <router-link tag="a" class="ar" to="/usage-policy">
              <span class="icon"><i class="fas fa-address-book"></i> </span>
              سياسة الاستخدام
            </router-link>
            <router-link tag="a" class="en" to="/usage-policy">
              <span class="icon"><i class="fas fa-address-book"></i> </span>
              Usage Policy
            </router-link>
          </li>
          <li>
            <router-link tag="a" class="ar" to="/terms">
              <span class="icon"> <i class="fas fa-question"></i> </span>
              الشروط والحكام
            </router-link>
            <router-link tag="a" class="en" to="/terms">
              <span class="icon"> <i class="fas fa-question"></i> </span>
              Terms and conditions
            </router-link>
          </li>
          <li>
            <button type="button" class="ar" @click="changeLangEn()">
              <span class="icon"> <i class="fas fa-globe"></i></span>
              تغير اللغة
            </button>
            <button type="button" class="en" @click="changeLangAr()">
              <span class="icon"><i class="fas fa-globe"></i></span>
              Change Language
            </button>
          </li>
          <li>
            <router-link tag="a" class="ar" to="/about">
              <span class="icon">
                <i class="fas fa-exclamation-circle"></i
              ></span>
              عن التطبيق
            </router-link>
            <router-link tag="a" class="en" to="/about">
              <span class="icon">
                <i class="fas fa-exclamation-circle"></i
              ></span>
              About Us
            </router-link>
          </li>
          <li>
            <button type="button" class="ar" to="/" @click="iconSocial">
              <span class="icon"> <i class="fas fa-share-alt"></i></span>
              مشاركة الموقع
            </button>
            <button type="button" class="en" to="/" @click="iconSocial">
              <span class="icon"> <i class="fas fa-share-alt"></i></span>
              Share App
            </button>
            <div class="social-icon" :class="{ active: showSocial }">
              <ul>
                <li>
                  <ShareNetwork
                    network="facebook"
                    url="https://mawgood.com"
                    title="موقع  موجود"
                    description="موقع  موجود"
                    quote="موقع  موجود"
                    hashtags="موجود"
                  >
                    <span class="socialIcon">
                      <i class="fab fa-facebook-square"></i>
                    </span>
                  </ShareNetwork>
                </li>
                <li>
                  <ShareNetwork
                    network="twitter"
                    url="https://mawgood.com"
                    title="موقع  موجود"
                    description="موقع  موجود"
                    quote="موقع  موجود"
                    hashtags="موجود"
                  >
                    <span class="socialIcon">
                      <i class="fab fa-twitter-square"></i>
                    </span>
                  </ShareNetwork>
                </li>
                <li>
                  <ShareNetwork
                    network="whatsApp"
                    url="https://mawgood.com"
                    title="موقع  موجود"
                    description="موقع  موجود"
                    quote="موقع  موجود"
                    hashtags="موجود"
                  >
                    <span class="socialIcon">
                      <i class="fab fa-whatsapp-square"></i>
                    </span>
                  </ShareNetwork>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <router-link tag="a" class="ar" to="/contact">
              <span class="icon"> <i class="fas fa-phone"></i></span>
              اتصل بنا
            </router-link>
            <router-link tag="a" class="en" to="/contact">
              <span class="icon"> <i class="fas fa-phone"></i></span>
              Contact Us
            </router-link>
          </li>
          <li>
            <button type="button" class="ar" @click="logoutNow()">
              <span class="icon"> <i class="fas fa-unlock"></i></span>
              تسجيل الخروج
            </button>
            <button type="button" class="en" @click="logoutNow()">
              <span class="icon"> <i class="fas fa-unlock"></i></span>
              Log Out
            </button>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "settings",
  data() {
    return {
      pageLocation: window.location.href,
      showSocial: false,
    };
  },

  methods: {
    changeLangEn() {
      localStorage.setItem("lang", "en");
      location.reload();
    },
    changeLangAr() {
      localStorage.setItem("lang", "ar");
      location.reload();
    },
    logoutNow() {
      this.$router.go(this.$router.push({ name: "login" }));
      localStorage.removeItem("token");
    },
    iconSocial() {
      this.showSocial = !this.showSocial;
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  components: {},
};
</script>
