<template>
  <div class="main">
    <!-- title -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <p class="ar">الإشعارات</p>
              <p class="en">Notifications</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Load v-if="loading"></Load>
    <!--start favourite-->
    <div class="notification" v-else>
      <Load v-if="loadingDelete" class="load-form"></Load>
      <div class="container">
        <div class="not-found" v-if="cards == ''">
          <span><i class="fas fa-exclamation-triangle"></i> </span>
          <h3 class="ar">لا يوجد إشعارات</h3>
          <h3 class="en">There are no notifications</h3>
        </div>
        <div v-else>
          <div class="notificate" v-for="card in cards" :key="card.id">
            <div class="media">
              <img src="../../assets/images/fav.png" alt="" />
              <div class="media-body">
                <div class="top-media">
                  <p>{{ card.body }}</p>
                </div>
                <div class="bottom-media">
                  <span class="icon"><i class="far fa-calendar-alt"></i></span>
                  <span> {{ card.date }} </span>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="remove"
              @click="deleteNotification(card.id)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  name: "notification",
  data() {
    return {
      baseURL: "http://mawjoud.sa/mogood/public/api",
      loading: false,
      loadingDelete: false,
      cards: [],
    };
  },

  methods: {
    notification() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/getnotification", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.cards = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    deleteNotification(card_id) {
      this.loadingDelete= true,
      axios
        .post(
          this.baseURL + "/notification/delete",
          { id: card_id },
          {
            headers: {
              Authorization: "bearer" + localStorage.getItem("token"),
              lang: localStorage.getItem("lang"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then(() => {
           this.loadingDelete = false
          this.notification();
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => (this.loadingDelete = false));
    },
  },
  mounted() {
    this.notification();
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  components: {
    Load,
  },
};
</script>
