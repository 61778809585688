<template>
  <div class="bottom-nav">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <ul class="list-unstyled">
            <li class="nav-item">
              <router-link to="/" tag="a" class="nav-link">
                <span class="icon"><i class="fas fa-home"></i></span>
                <span class="ar">الرئيسية</span>
                <span class="en">Home</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/favourite" tag="a" class="nav-link">
                <span class="icon"><i class="far fa-heart"></i></span>
                <span class="ar">المفضلة</span>
                <span class="en">Favourite</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/site-treaty" tag="a" class="nav-link diff">
                <span class="icon"><i class="fas fa-plus"></i></span>
                <span class="ar">اضف اعلان</span>
                <span class="en">Add Ads</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/notification" tag="a" class="nav-link">
                <span class="icon"><i class="far fa-bell"></i></span>
                <span class="ar">الاشعارات</span>
                <span class="en">Notifications</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/setting" tag="a" class="nav-link">
                <span class="icon"><i class="fas fa-cog"></i></span>
                <span class="ar">الاعدادات</span>
                <span class="en">Settings</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
