<template>
  <div class="main">
    <!-- header -->
    <Header></Header>
    <!-- site section -->
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <!-- footer tabs -->
    <TabsFooter></TabsFooter>
    <!-- footer -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./main-components/header";
import TabsFooter from "./main-components/footer-tabs";
import Footer from "./main-components/footer";
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
       baseURL: "http://mawjoud.sa/mogood/public/api",
    };
  },
  methods: {
    getUserData() {
      axios
        .get(this.baseURL + "/user", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          localStorage.setItem('userMessages', res.data.user.messages_count)
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    if (token) {
      this.getUserData()
    }
   
  },
  created() {
    var body = document.querySelector("body");
    var lang = localStorage.getItem("lang")
    if (lang == "en") {
      body.classList.add("ltr");
    } else {
      body.classList.remove("ltr");
    }
    if(!lang) {
      localStorage.setItem("lang", "ar")
    }
  },
  components: {
    Header,
    TabsFooter,
    Footer,
  },
};
</script>
