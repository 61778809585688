<template>
  <div class="main">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="gray-green">
              <span class="ar">حساب العمولة</span>
              <span class="en">Commission account</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="commission-details">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="com-det">
              <p class="ar">
                اذا كان المعلن مكتب عقاري أو مؤسسة أو شركة عقارية فيتم دفع
                {{ first_persentage }}% نسبة عمولة من مبلغ السعي الذي تيسر
                الحصول عليه عند إتمام عملية البيع او الإيجار
              </p>
              <p class="en">
                If the advertiser is a real estate office, institution, or real
                estate company, then payment is made
                {{ first_persentage }}% commission percentage of the quest
                amount you facilitate Obtaining it upon completion of the sale
                or the rent
              </p>
              <form>
                <div class="calc-com">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control ar"
                      v-model="first_number"
                      @keyup="firstMethod"
                      placeholder="مبلغ السعي الذي تيسر الحصول عليه"
                    />
                    <input
                      type="text"
                      class="form-control en"
                      v-model="first_number"
                      @keyup="firstMethod"
                      placeholder="The amount of pursuit that you can get"
                    />
                  </div>
                  <div class="calc-result">
                    <p class="ar">
                      قيمة العمولة المستحقة للموقع = {{ first_result }} ريال
                    </p>
                    <p class="en">
                      The value of the commission due for the website =
                      {{ first_result }} riyals
                    </p>
                    <button
                      type="button"
                      class="btn calc-btn ar"
                      @click="pay(first_result)"
                      :disabled="first_result ? false : true"
                    >
                      دفع
                    </button>
                    <button
                      type="button"
                      class="btn calc-btn en"
                      @click="pay(first_result)"
                      :disabled="first_result ? false : true"
                    >
                      pay
                    </button>
                  </div>
                </div>
                <!-- <button
                  type="button"
                  class="btn form-btn btn-block ar"
                  @click="show_first()"
                >
                  دفع
                </button>
                <button
                  type="button"
                  class="btn form-btn btn-block en"
                  @click="show_first()"
                >
                  pay
                </button> -->
                <PinShow @pay="pay" v-if="firstPay"></PinShow>
              </form>
            </div>
            <hr class="line" />
            <div class="com-det">
              <p class="ar">
                إذا كان المعلن المالك أو من يمثله فإنه يتم دفع نسبة
                {{ second_persentage }}% من قيمة العقار أو مبلغ الإيجار
              </p>
              <p class="en">
                If the advertiser is the direct owner or his representative,
                then a percentage is paid {{ second_persentage }}% of the
                property value or value Rent
              </p>
              <form action="payment.html" method="get">
                <div class="calc-com">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control ar"
                      v-model="second_number"
                      @keyup="secondMethod"
                      placeholder="قيمة العقار أو مبلغ الإيجار"
                    />
                    <input
                      type="text"
                      class="form-control en"
                      v-model="second_number"
                      @keyup="secondMethod"
                      placeholder="The value of the property or the rental value"
                    />
                  </div>
                  <div class="calc-result">
                    <p class="ar">
                      قيمة العمولة المستحقة للموقع = {{ second_result }} ريال
                    </p>
                    <p class="en">
                      The value of the commission due for the website =
                      {{ second_result }} riyals
                    </p>
                    <button
                      type="button"
                      class="btn calc-btn ar"
                      @click="pay(second_result)"
                      :disabled="second_result ? false : true"
                    >
                      دفع
                    </button>
                    <button
                      type="button"
                      class="btn calc-btn en"
                      @click="pay(second_result)"
                      :disabled="second_result ? false : true"
                    >
                      pay
                    </button>
                    <!-- <button class="btn calc-btn">دفع</button> -->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://polyfill.io/v3/polyfill.min.js?features=fetch"></script>
<script src="https://cdn.moyasar.com/mpf/1.4.0/moyasar.js"></script>
<script>
import axios from "axios";
import PinShow from "../show/pin-show.vue";
var persianNumbers = [
  /۰/g,
  /۱/g,
  /۲/g,
  /۳/g,
  /۴/g,
  /۵/g,
  /۶/g,
  /۷/g,
  /۸/g,
  /۹/g,
];
var arabicNumbers = [
  /٠/g,
  /١/g,
  /٢/g,
  /٣/g,
  /٤/g,
  /٥/g,
  /٦/g,
  /٧/g,
  /٨/g,
  /٩/g,
];
export default {
  name: "contact",
  data() {
    return {
      loading: false,
      baseURL: "http://mawjoud.sa/mogood/public/api",
      first_persentage: "",
      second_persentage: "",
      first_number: "",
      second_number: "",
      first_result: "",
      second_result: "",
      errorShow: false,
      show: false,
      errorColor: "",
      errorMessage: "",
      loaded: false,
      packages: "",
      baseURL: "http://mawjoud.sa/mogood/public/api",
      allData: {
        package: "",
      },
      showPayForm: true,
      callback_url: null,
    };
  },
  mounted() {
    this.getCompany();
    this.getPerson();
  },
  methods: {
    pay(value) {
      var myDiv = document.querySelector(".moyaser-pop");
      myDiv.classList.add("show");
      document.querySelector(".moy-over").onclick = function () {
        document.querySelector(".moyaser-pop").classList.toggle("show");
      };
      Moyasar.init({
        element: ".mysr-form",
        amount: value * 100,
        currency: "SAR",
        description: "دفع العمولة",
        publishable_api_key: "pk_test_BEArai9bYYe7oTQyBT5vGkXxq78e8SWqGgHE6SgY",
        callback_url: this.callback_url,
        methods: ["creditcard"],
        language: localStorage.getItem("lang"),
      });
    },
    getCompany() {
      axios
        .get(this.baseURL + "/setting/campany_comption", {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.first_persentage = res.data.data.value;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally();
    },
    getPerson() {
      axios
        .get(this.baseURL + "/setting/person_comption", {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.second_persentage = res.data.data.value;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally();
    },
    firstMethod() {
      if (typeof this.first_number === "string") {
        for (var i = 0; i < 10; i++) {
          this.first_number = this.first_number
            .replace(persianNumbers[i], i)
            .replace(arabicNumbers[i], i);
        }
      }
      return (this.first_result =
        (this.first_persentage * this.first_number) / 100);
    },
    secondMethod() {
      if (typeof this.second_number === "string") {
        for (var i = 0; i < 10; i++) {
          this.second_number = this.second_number
            .replace(persianNumbers[i], i)
            .replace(arabicNumbers[i], i);
        }
      }
      return (this.second_result =
        (this.second_persentage * this.second_number) / 100);
    },
  },
  created() {
    this.firstMethod();
    this.secondMethod();
    this.callback_url = "http://mawjoud.sa/#/thank-you";
  },
  components: {
    PinShow,
  },
};
</script>
