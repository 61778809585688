import Vue from 'vue'
import App from './App.vue'
// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
// fontawesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
// style
import './../src/assets/css/animation.css'
import './../src/assets/css/style.css'
import './../src/assets/css/responsive.css'
// vue resource
import VueResource from 'vue-resource'
// router
import router from './router/router.js'
// map
import * as VueGoogleMaps from 'vue2-google-maps';
//vuex
import store from "./store";
// login
import axios from 'axios';
// share on social media
import VueSocialSharing from 'vue-social-sharing'

Vue.config.productionTip = false
Vue.use(VueResource);
// ckeditor
import CKEditor from 'ckeditor4-vue';
Vue.use(CKEditor);
// time
const moment = require('moment')
var lang = localStorage.getItem("lang")
if (lang == "ar") {
    require('moment/locale/ar')
}
Vue.use(require('vue-moment'), {
    moment
});
// map
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDx--BILIM2LjZBxVMjGYVb8YqrY-Vk_Yk',
        libraries: 'places'
    },
});
// loading
var eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;
// share on social media
Vue.use(VueSocialSharing);

// login
router.beforeEach((to, from, next) => {
    axios.defaults.headers.common['Authorization'] = "bearer " + localStorage.getItem('token');
    next()
});

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')