<template>
  <div class="data">
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <span class="ar">اضف اعلان</span>
              <span class="en">Add Advertisement</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add ads -->
    <Load v-if="loading"></Load>
    <div class="add-advertisment" v-else>
      <Load v-if="loadingAdd" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="container" v-if="success">
        <div class="not-found">
          <span><i class="far fa-check-circle"></i></span>
          <h3 class="ar">تم إضافةالاعلان بنجاح</h3>
          <h3 class="en">Advertisement was successfully added</h3>
        </div>
      </div>
      <div class="container" v-else>
        <div class="my-form">
          <!-- <span class="ar"
            >اضافة الصور للاعلان تدعم العرض وتزيد الطلب اضافة صور الاعلان (حد
            اقصى 10 صور)</span
          >
          <span class="en">
            Adding images to the advertisement supports the display and
            increases the demand. Adding images for the advertisement (limit Max
            10 photos)</span
          > -->
          <form>
            <div class="form-group">
              <div class="upload-block ar">
                <div class="upload_wrap">
                  <h4>تحميل صور الاعلان</h4>
                  <input
                    type="file"
                    class="form-control"
                    multiple
                    accept="image/*"
                    @change="uploadImage"
                  />
                </div>
                <div
                  v-for="(image, index) in cardData.imagesSrc"
                  :key="index"
                  class="upload_item"
                >
                  <span class="upload_del" @click="remmoveImage(index)">
                    x
                  </span>
                  <img :src="image.src" class="upload_img" />
                </div>
              </div>
              <div class="upload-block en">
                <div class="upload_wrap">
                  <h4>Upload Ads Images</h4>
                  <input
                    type="file"
                    class="form-control"
                    multiple
                    accept="image/*"
                    @change="uploadImage"
                  />
                </div>
                <div
                  v-for="(image, index) in cardData.imagesSrc"
                  :key="index"
                  class="upload_item"
                >
                  <span class="upload_del" @click="remmoveImage(index)">
                    x
                  </span>
                  <img :src="image.src" class="upload_img" />
                </div>
              </div>
            </div>
            <!-- first select -->
            <div class="form-group">
              <select
                class="custom-select diaa-select ar"
                @change="chooseType(), getServices()"
                v-model="cardData.type"
              >
                <option value="">إختر قسم الإعلان</option>
                <option value="taswiq_eqara">موجود لتسويق العقار</option>
                <option value="bina_wataemir">موجود للبناء والتعمير</option>
                <option value="sianuh">موجود لخدمات وصيانة المنازل</option>
                <option value="Weddings and occasions">
                  موجود للافراح والمناسبات والكتشات
                </option>
              </select>
              <select
                class="custom-select diaa-select en"
                @change="chooseType()"
                v-model="cardData.type"
              >
                <option value="">Choose advertisment part</option>
                <option value="taswiq_eqara">
                  Mawgoud to market the property
                </option>
                <option value="bina_wataemir">
                  Mawgoud for construction and reconstruction
                </option>
                <option value="sianuh">
                  Mawgoud for home services and maintenance
                </option>
                <option value="Weddings and occasions">
                  Mawgoud for weddings, events and chats
                </option>
              </select>
            </div>
            <!-- areas -->
            <div
              class="form-group"
              v-if="selectType.firstSelect || selectType.otherSelect"
            >
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                @input="getGoverment()"
                v-model="cardData.area_id"
                :max-height="600"
                placeholder="إختر اسم المنطقة"
                :options="areas.map((area) => area.id)"
                :custom-label="(opt) => areas.find((x) => x.id == opt).name"
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                @input="getGoverment()"
                v-model="cardData.area_id"
                placeholder="Choose the area name"
                :options="areas.map((area) => area.id)"
                :custom-label="(opt) => areas.find((x) => x.id == opt).name"
              >
              </multiselect>
            </div>
            <!-- goverment -->
            <div
              class="form-group"
              v-if="selectType.firstSelect || selectType.otherSelect"
            >
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                @input="getCity()"
                v-model="cardData.goverment_id"
                :max-height="600"
                placeholder="إختر العاصمة الإدارية أو المحافظة"
                :options="goverments.map((goverment) => goverment.id)"
                :custom-label="
                  (opt) => goverments.find((x) => x.id == opt).name
                "
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                @input="getCity()"
                v-model="cardData.goverment_id"
                placeholder="Choose the administrative capital or province"
                :options="goverments.map((goverment) => goverment.id)"
                :custom-label="
                  (opt) => goverments.find((x) => x.id == opt).name
                "
              >
              </multiselect>
            </div>
            <!-- city -->
            <div
              class="form-group"
              v-if="selectType.firstSelect || selectType.otherSelect"
            >
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                @input="getResidential()"
                v-model="cardData.city_id"
                :max-height="600"
                placeholder="إختر إسم العاصمة الإدارية أو المركز"
                :options="cities.map((city) => city.id)"
                :custom-label="(opt) => cities.find((x) => x.id == opt).name"
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                @input="getResidential()"
                v-model="cardData.city_id"
                placeholder="Choose the name of the administrative capital or center"
                :options="cities.map((city) => city.id)"
                :custom-label="(opt) => cities.find((x) => x.id == opt).name"
              >
              </multiselect>
            </div>
            <!-- residential -->
            <div class="form-group" v-if="selectType.firstSelect">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                @input="getBuilds()"
                v-model="cardData.residential_id"
                :max-height="600"
                placeholder="إختر اسم الحي"
                :options="residentials.map((residential) => residential.id)"
                :custom-label="
                  (opt) => residentials.find((x) => x.id == opt).name
                "
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                @input="getBuilds()"
                v-model="cardData.residential_id"
                placeholder="Choose a neighborhood name"
                :options="residentials.map((residential) => residential.id)"
                :custom-label="
                  (opt) => residentials.find((x) => x.id == opt).name
                "
              >
              </multiselect>
            </div>
            <!-- building -->
            <div class="form-group" v-if="selectType.firstSelect">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.building_id"
                :max-height="600"
                placeholder="إختر اسم العقار"
                :options="builds.map((build) => build.id)"
                :custom-label="(opt) => builds.find((x) => x.id == opt).name"
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.building_id"
                placeholder="Choose the property name"
                :options="builds.map((build) => build.id)"
                :custom-label="(opt) => builds.find((x) => x.id == opt).name"
              >
              </multiselect>
            </div>
            <!-- type -->
            <div class="form-group" v-if="selectType.firstSelect">
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.rent_type"
              >
                <option value="">للبيع ام للإيجار</option>
                <option value="sale">بيع</option>
                <option value="rent">إيجار</option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.rent_type"
              >
                <option value="">For sale or for rent</option>
                <option value="sale">sale</option>
                <option value="rent">rent</option>
              </select>
            </div>
            <!-- services -->
            <div class="form-group" v-if="selectType.otherSelect">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="cardData.service_id"
              >
                <option value="">إختر الخدمة</option>
                <option
                  v-for="service in services"
                  :key="service.id"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="cardData.service_id"
              >
                <option value="">Choose the service</option>
                <option
                  v-for="service in services"
                  :key="service.id"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>
            <!-- ---------------------- -->
            <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="عنوان الاعلان"
                v-model="cardData.subject"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="Advertisment name"
                v-model="cardData.subject"
              />
            </div>
            <div class="form-group">
              <h4 class="ar">وصف الإعلان</h4>
              <ckeditor
                class="form-control ar"
                tag-name="textarea"
                placeholder="وصف الاعلان"
                v-model="cardData.description"
                :config="editorConfigAr"
              ></ckeditor>
              <h4 class="en">Advertisment description</h4>
              <ckeditor
                class="form-control en"
                tag-name="textarea"
                placeholder=" Advertisment description "
                v-model="cardData.description"
                :config="editorConfigEn"
              ></ckeditor>
            </div>
            <!-- <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="الموقع على الخريطة"
                id="autocomplete_ar"
                @click="getAddressAr"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="Location on the map"
                id="autocomplete_en"
                @click="getAddressEn"
              />
            </div> -->
            <div class="form-group">
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <input
                    type="text"
                    class="form-control ar"
                    id="phoneNumber"
                    placeholder="رقم الجوال للتواصل"
                    v-model="cardData.phone_num"
                    lang="en"
                  />
                  <input
                    type="text"
                    class="form-control en"
                    id="phoneNumber"
                    placeholder="Mobile number to contact"
                    v-model="cardData.phone_num"
                    lang="en"
                  />
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="site-treaty ar" style="padding: 0">
                    <div class="treaty" style="padding: 0">
                      <div class="ckbox">
                        <input
                          type="checkbox"
                          v-model="cardData.usePhone"
                          id="usePhone"
                          value="usePhone"
                          name="d"
                          @change="usePhoneClick"
                        />
                        <label for="usePhone">أرغب في إخفاء رقم الجوال</label>
                      </div>
                    </div>
                  </div>
                  <div class="site-treaty en" style="padding: 0">
                    <div class="treaty" style="padding: 0">
                      <div class="ckbox">
                        <input
                          type="checkbox"
                          v-model="cardData.usePhone"
                          id="usePhone"
                          value="usePhone"
                          name="d"
                          @change="usePhoneClick"
                        />
                        <label for="usePhone">Want to hide phone number </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="رقم الواتس للتواصل"
                v-model="cardData.phone_whats"
                lang="en"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="WhatsApp number for contact"
                v-model="cardData.phone_whats"
                lang="en"
              />
            </div> -->
            <div class="treaty">
              <ul class="list-inline ar">
                <li class="ckbox">
                  <input
                    @change="!allData.check3"
                    type="checkbox"
                    v-model="allData.check3"
                    id="radio3"
                    name="d"
                  />
                  <label for="radio3" style="font-size: 20px" class="mr-2"
                    >هل تريد تثبيت إعلاناتك ؟</label
                  >
                </li>
              </ul>
              <ul class="list-inline en">
                <li class="ckbox">
                  <input
                    @change="!allData.check1"
                    type="checkbox"
                    v-model="allData.check1"
                    id="radio"
                    name="d"
                  />
                  <label for="radio"
                    >I pledge before God to pay the application commission
                    described above in the event that the sale of the property
                    is carried out through the application, and God is what I
                    say is a martyr</label
                  >
                </li>
                <li class="ckbox">
                  <input
                    @change="!allData.check2"
                    type="checkbox"
                    v-model="allData.check2"
                    id="radio2"
                    name="d"
                  />
                  <label for="radio2"
                    >I pledge to pay the commission within a maximum period of
                    one week from the date of sale or rent</label
                  >
                </li>
                <li class="ckbox">
                  <input
                    @change="!allData.check3"
                    type="checkbox"
                    v-model="allData.check3"
                    id="radio3"
                    name="d"
                  />
                  <label for="radio3">Do you want pend this ads ?</label>
                </li>
              </ul>
              <!-- <PinShow @pay="pay" v-if="allData.check3"></PinShow> -->
            </div>
            <button
              type="button"
              class="btn form-btn btn-block ar"
              @click="addAdvertisment"
            >
              اضف الاعلان
            </button>
            <button
              type="button"
              class="btn form-btn btn-block en"
              @click="addAdvertisment"
            >
              Add Advertisment
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDx--BILIM2LjZBxVMjGYVb8YqrY-Vk_Yk"></script>
<script>
import axios from "axios";
import Load from "./../loading";
import Multiselect from "vue-multiselect";
import PinShow from "../show/pin-show.vue";
export default {
  data() {
    return {
      editorConfigAr: {
        contentsLangDirection: "rtl",
        contentsCss: "./../../../asstes/css/style.css",
      },
      editorConfigEn: {
        contentsLangDirection: "ltr",
      },
      errorMessage: "",
      errorShow: false,
      errorColor: "",
      type: "",
      selectType: {
        firstSelect: false,
        otherSelect: false,
      },
      loading: false,
      loadingAdd: false,
      loadingSelect: false,
      success: false,
      baseURL: "http://mawjoud.sa/mogood/public/api",
      // areas
      areas: [],
      // goverment
      goverments: [],
      // city
      cities: [],
      // residential
      residentials: [],
      // build
      builds: [],
      //   services
      services: [],
      cardData: {
        type: "",
        area_id: "",
        goverment_id: "",
        city_id: "",
        residential_id: "",
        building_id: "",
        rent_type: "",
        service_id: "",
        // ----------
        subject: "",
        description: "",
        address: "",
        // latitude: "",
        // longitude: "",
        phone_num: "",
        usePhone: "",
        // phone_whats: "",
        image: [],
        imagesSrc: [],
        check1: "",
        userBackage: "",
      },
      allData: {
        check3: false,
      },
      userBackage: false,
    };
  },
  methods: {
    //emit data
    pay() {
      localStorage.setItem("my_type", this.cardData.type);
      localStorage.setItem("my_area_id", this.cardData.area_id);
      localStorage.setItem("my_goverment_id", this.cardData.goverment_id);
      localStorage.setItem("my_city_id", this.cardData.city_id);
      localStorage.setItem("my_residential_id", this.cardData.residential_id);
      localStorage.setItem("my_building_id", this.cardData.building_id);
      localStorage.setItem("my_rent_type", this.cardData.rent_type);
      localStorage.setItem("my_service_id", this.cardData.service_id);
      localStorage.setItem("my_subject", this.cardData.subject);
      localStorage.setItem("my_description", this.cardData.description);
      localStorage.setItem("my_phone_num", this.cardData.phone_num);
      localStorage.setItem("my_address", this.cardData.address);
      localStorage.setItem("my_usePhone", this.cardData.usePhone);
      localStorage.setItem("my_imagesSrc", this.cardData.imagesSrc);
    },
    // upload image
    uploadImage(e) {
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        let img = {
          src: URL.createObjectURL(selectedFiles[i]),
          file: selectedFiles[i],
        };
        this.cardData.imagesSrc.push(img);
        this.cardData.image = this.cardData.imagesSrc;
      }
    },
    remmoveImage(index) {
      this.cardData.imagesSrc.splice(index, 1);
    },
    chooseType() {
      if (this.cardData.type == "taswiq_eqara") {
        this.selectType.firstSelect = true;
        this.selectType.otherSelect = false;
      } else if (
        this.cardData.type == "bina_wataemir" ||
        this.cardData.type == "sianuh" ||
        this.cardData.type == "Weddings and occasions"
      ) {
        this.selectType.firstSelect = false;
        this.selectType.otherSelect = true;
      } else {
        this.selectType.firstSelect = false;
        this.selectType.otherSelect = false;
      }
    },

    getUserData() {
      axios
        .get(this.baseURL + "/user", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.userBackage = res.data.user.is_packages;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // areas
    getAreas() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/getarea", {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.areas = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    // goverment
    getGoverment() {
      this.loadingSelect = true;
      axios
        .get(this.baseURL + "/getgoverment?area_id=" + this.cardData.area_id, {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loadingSelect = false;
          this.goverments = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // city
    getCity() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL + "/getcity?goverment_id=" + this.cardData.goverment_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.cities = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // residential
    getResidential() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL + "/getresidential?city_id=" + this.cardData.city_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.residentials = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // builds
    getBuilds() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL +
            "/getbuilding?residential_id=" +
            this.cardData.residential_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.builds = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // services
    getServices() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL +
            "/getservice?type_id=" +
            (this.cardData.type == "bina_wataemir"
              ? "2"
              : this.cardData.type == "sianuh"
              ? "3"
              : this.cardData.type == "Weddings and occasions"
              ? "8"
              : ""),
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.services = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // ------------- get location from map ---------------------
    // getAddressAr() {
    //   var self = this;
    //   var input = document.getElementById("autocomplete_ar");
    //   var searchBox = new google.maps.places.SearchBox(input);
    //   searchBox.addListener("places_changed", function() {
    //     var places = searchBox.getPlaces();
    //     if (places.length == 0) {
    //       return;
    //     }
    //     var bounds = new google.maps.LatLngBounds();
    //     places.forEach(function(place) {
    //       bounds.extend(place.geometry.location);
    //       place.geometry.location.lng();
    //       place.geometry.location.lat();
    //       place.formatted_address;
    //       self.cardData.longitude = place.geometry.location.lng();
    //       self.cardData.latitude = place.geometry.location.lat();
    //       self.cardData.address = place.formatted_address;
    //     });
    //   });
    // },
    // getAddressEn() {
    //   var self = this;
    //   var input = document.getElementById("autocomplete_en");
    //   var searchBox = new google.maps.places.SearchBox(input);
    //   searchBox.addListener("places_changed", function() {
    //     var places = searchBox.getPlaces();
    //     if (places.length == 0) {
    //       return;
    //     }
    //     var bounds = new google.maps.LatLngBounds();
    //     places.forEach(function(place) {
    //       bounds.extend(place.geometry.location);
    //       place.geometry.location.lng();
    //       place.geometry.location.lat();
    //       place.formatted_address;
    //       self.cardData.longitude = place.geometry.location.lng();
    //       self.cardData.latitude = place.geometry.location.lat();
    //       self.cardData.address = place.formatted_address;
    //     });
    //   });
    // },
    usePhoneClick() {
      var phoneNumber = document.getElementById("phoneNumber");
      if (this.cardData.usePhone == true) {
        phoneNumber.classList.add("disabled");
      } else {
        phoneNumber.classList.remove("disabled");
      }
    },
    // -----------------------
    addAdvertisment() {
      const data = new FormData();
      var images = this.cardData.image;
      images.forEach((item) => {
        images = item.file;
        console.log(images);
        data.append("image[]", images);
      });
      data.append("type", this.cardData.type);
      data.append("area_id", this.cardData.area_id);
      data.append("goverment_id", this.cardData.goverment_id);
      data.append("city_id", this.cardData.city_id);
      data.append("residential_id", this.cardData.residential_id);
      data.append("building_id", this.cardData.building_id);
      data.append("rent_type", this.cardData.rent_type);
      if (this.cardData.service_id) {
        data.append("service_id", this.cardData.service_id);
      }
      data.append("subject", this.cardData.subject);
      data.append("description", this.cardData.description);
      if (this.cardData.phone_num) {
        data.append("phone_num", this.cardData.phone_num);
      }
      // data.append("phone_whats", this.cardData.phone_whats);
      // data.append("latitude", this.cardData.latitude);
      // data.append("longitude", this.cardData.longitude);
      if (this.cardData.address) {
        data.append("address", this.cardData.address);
      }
      this.loadingAdd = true;

      axios
        .post(this.baseURL + "/advertising/create", data, {
          headers: {
            lang: localStorage.getItem("lang"),
            Authorization: "bearer" + localStorage.getItem("token"),
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loadingAdd = false;
          console.log(res.data.data);
          if (res.status == 200) {
            this.success = true;
            if (this.allData.check3 == true) {
              this.$router.push("/pin-show/" + res.data.data.advertising_id);
            }
          }
        })
        .catch((error) => {
          this.loadingAdd = false;
          console.log(error.response);
          if (error.response.status == 422) {
            this.errorColor = "faild";
            this.errorShow = true;
            this.errorMessage = error.response.data.errors;
          }
        })
        .finally(() => (this.loadingAdd = false));
    },
    getUserData() {
      this.loading = true;
      axios
        .get(this.baseURL + "/user", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.userBackage = res.data.user.is_packages;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    var accept = localStorage.getItem("accept-conditions");
    if (!token) {
      this.$router.push({ name: "login" });
    }
    if (!accept) {
      this.$router.push({ name: "site-treaty" });
    }
    this.getAreas();
    // this.getServices();
    this.getUserData();
  },
  name: "add-show",
  components: {
    Load,
    Multiselect,
    PinShow,
  },
  computed: {
    get_trans_id() {
      return this.$store.getters.getId;
    },
  },
};
</script>
