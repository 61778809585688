<template>
  <div class="loading-section">
    <div class="loader">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  name: "load"
};
</script>