<template>
  <div class="main">
    <!-- title -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <p class="ar">المحادثات</p>
              <p class="en">All Chats</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Load v-if="loading"></Load>
    <!--start favourite-->
    <div class="favourite" v-else>
      <div class="container">
        <div class="not-found" v-if="cards == ''">
          <span><i class="fas fa-exclamation-triangle"></i> </span>
          <h3 class="ar">لا يوجد محادثات</h3>
          <h3 class="en">There are no chats</h3>
        </div>
        <div class="all-chats" v-else>
          <div class="chat-card" v-for="card in cards" :key="card.id">
            <router-link :to="'/chat/' + card.id" tag="a">
              <div class="image">
                <img :src="card.revicer_image" />
              </div>
              <div class="text">
                <h3>{{ card.revicer_name }}</h3>
                <p v-if="card.last_message == null" class="ar">لا يوجد رسائل</p>
                <p v-if="card.last_message == null" class="en">there is no message here</p>
                <p v-else>{{ card.last_message }}</p>
                <span class="date" v-if=" card.last_date != null"> {{ card.last_date }} </span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  name: "Favourite",
  data() {
    return {
      baseURL: "http://mawjoud.sa/mogood/public/api",
      loading: false,
      cards: [],
    };
  },

  methods: {
    chatsCard() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/getchat", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.cards = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.chatsCard();
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  components: {
    Load,
  },
};
</script>
