<template>
  <div class="main">
    <!-- title -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <p class="ar">المحادثات</p>
              <p class="en">All Chats</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Load v-if="loading"></Load>
    <!--start favourite-->
    <div class="favourite" v-else>
      <div class="container">
        <div class="all-chats">
          <div class="not-found" v-if="cards == ''">
            <span><i class="fas fa-exclamation-triangle"></i> </span>
            <h3 class="ar">لا يوجد محادثات</h3>
            <h3 class="en">There are no chats</h3>
          </div>
          <ol class="chat-section" ref="messageChat" v-else>
            <li
              :class="[item.is_my_message == 1 ? 'other' : 'self']"
              class="fadeIn"
              v-for="item in cards"
              :key="item.id"
            >
              <div class="avatar">
                <img :src="item.sender_image" draggable="false" />
              </div>
              <div class="msg">
                <p>{{ item.body }}</p>
                <time> {{ item.time }} </time>
              </div>
            </li>
          </ol>
          <div class="text-box">
            <textarea
              name=""
              id=""
              placeholder="Write your message here ...."
              v-model="messageText"
              class="en"
            ></textarea>
            <textarea
              name=""
              id=""
              placeholder="إكتب نص الرساله هنا ....."
              v-model="messageText"
              class="ar"
            ></textarea>
            <button
              type="button"
              class="btn send-message-button button"
              ref="sendMessage"
              @click="sendMessage"
            >
              <i class="fas fa-spinner"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
// import firebase from "./../../firebase";
export default {
  name: "Favourite",
  data() {
    return {
      baseURL: "http://mawjoud.sa/mogood/public/api",
      loading: false,
      cards: [],
      id: this.$route.params.id,
      messageText: "",
    };
  },

  methods: {
    chatsCard() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/getmessages?chat_id=" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.cards = res.data.data.messages;
           setTimeout(() => {
            this.$refs["messageChat"].scrollTop =
              this.$refs["messageChat"].scrollHeight -
              this.$refs["messageChat"].clientHeight;
          }, 50);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    sendMessage() {
      if (this.messageText != "") {
        const submitButton = this.$refs["sendMessage"];
        submitButton.classList.add("click");
        const data = new FormData();
        data.append("chat_id", this.id);
        data.append("body", this.messageText);
        axios
          .post(this.baseURL + "/create/message", data, {
            headers: {
              lang: localStorage.getItem("lang"),
              Authorization: "bearer" + localStorage.getItem("token"),
              "Content-type": `multipart/form-data;`,
              Accept: "/",
            },
          })
          .then(() => {
            this.getChatAgain();
            var container = this.$el.querySelector(".chat-section");
            container.scrollTop = container.scrollHeight;
            this.messageText = "";
            submitButton.classList.remove("click");
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    getChatAgain() {
      axios
        .get(this.baseURL + "/getmessages?chat_id=" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.cards = res.data.data.messages;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // realTimeChat() {
    //   let viewMessage = this;
    //   const itemsRef = firebase.database().ref("messages");
    //   itemsRef.on("value", (snapshot) => {
    //     let data = snapshot.val();
    //     let messages = [];
    //     Object.keys(data).forEach((key) => {
    //       messages.push({
    //         id: key,
    //         username: data[key].username,
    //         text: data[key].text,
    //       });
    //     });
    //     viewMessage.cards = messages;
    //   });
    // },
  },
  mounted() {
    this.chatsCard();
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  components: {
    Load,
  },
};
</script>
