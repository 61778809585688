<template>
  <div class="main all_ser">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray"></div>
          </div>
        </div>
      </div>
    </div>
    <Load v-if="loading"></Load>
    <div class="search-sections" v-else>
      <div class="container">
        <div class="my-form">
          <form>
            <!-- areas -->
            <div class="form-group">
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                @input="getGoverment()"
                v-model="filterData.area_id"
                :max-height="600"
                placeholder="إختر اسم المنطقة"
                :options="areas.map((area) => area.id)"
                :custom-label="(opt) => areas.find((x) => x.id == opt).name"
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                @input="getGoverment()"
                v-model="filterData.area_id"
                placeholder="Choose the area name"
                :options="areas.map((area) => area.id)"
                :custom-label="(opt) => areas.find((x) => x.id == opt).name"
              >
              </multiselect>
            </div>
            <!-- goverment -->
            <div class="form-group">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                @input="getCity()"
                v-model="filterData.goverment_id"
                :max-height="600"
                placeholder="إختر العاصمة الإدارية أو المحافظة"
                :options="goverments.map((goverment) => goverment.id)"
                :custom-label="
                  (opt) => goverments.find((x) => x.id == opt).name
                "
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                @input="getCity()"
                v-model="filterData.goverment_id"
                placeholder="Choose the administrative capital or province"
                :options="goverments.map((goverment) => goverment.id)"
                :custom-label="
                  (opt) => goverments.find((x) => x.id == opt).name
                "
              >
              </multiselect>
            </div>
            <!-- city -->
            <div class="form-group">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                @input="getResidential()"
                v-model="filterData.city_id"
                :max-height="600"
                placeholder="إختر إسم العاصمة الإدارية أو المركز"
                :options="cities.map((city) => city.id)"
                :custom-label="(opt) => cities.find((x) => x.id == opt).name"
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                @input="getResidential()"
                v-model="filterData.city_id"
                placeholder="Choose the name of the administrative capital or center"
                :options="cities.map((city) => city.id)"
                :custom-label="(opt) => cities.find((x) => x.id == opt).name"
              >
              </multiselect>
            </div>
            <!-- residential -->
            <div class="form-group">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                @input="getBuilds()"
                v-model="filterData.residential_id"
                :max-height="600"
                placeholder="إختر اسم الحي"
                :options="residentials.map((residential) => residential.id)"
                :custom-label="
                  (opt) => residentials.find((x) => x.id == opt).name
                "
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                @input="getBuilds()"
                v-model="filterData.residential_id"
                placeholder="Choose a neighborhood name"
                :options="residentials.map((residential) => residential.id)"
                :custom-label="
                  (opt) => residentials.find((x) => x.id == opt).name
                "
              >
              </multiselect>
            </div>
            <!-- building -->
            <div class="form-group">
              <img
                class="loading-select"
                v-if="loadingSelect"
                src="../../assets/images/icons/loading.gif"
              />
              <multiselect
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="filterData.building_id"
                :max-height="600"
                placeholder="إختر اسم العقار"
                :options="builds.map((build) => build.id)"
                :custom-label="(opt) => builds.find((x) => x.id == opt).name"
              >
              </multiselect>
              <multiselect
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="filterData.building_id"
                placeholder="Choose the property name"
                :options="builds.map((build) => build.id)"
                :custom-label="(opt) => builds.find((x) => x.id == opt).name"
              >
              </multiselect>
            </div>
            <!-- type -->
            <div class="form-group">
              <select
                class="custom-select diaa-select ar"
                name="statusformA"
                id="statusformA"
                v-model="filterData.rent_type"
              >
                <option value="">للبيع ام للإيجار</option>
                <option value="sale">بيع</option>
                <option value="rent">إيجار</option>
              </select>
              <select
                class="custom-select diaa-select en"
                name="statusformA"
                id="statusformA"
                v-model="filterData.rent_type"
              >
                <option value="">For sale or for rent</option>
                <option value="sale">sale</option>
                <option value="rent">rent</option>
              </select>
            </div>
            <button
              type="button"
              class="btn form-btn btn-block"
              @click="sendFilter"
            >
              <span class="ar"> بحث </span>
              <span class="en"> Search </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
import Multiselect from "vue-multiselect";
export default {
  name: "taswiq_eqara",
  data() {
    return {
      loading: false,
      loadingSelect: false,
      baseURL: "http://mawjoud.sa/mogood/public/api",
      // areas
      areas: [],
      // goverment
      goverments: [],
      // city
      cities: [],
      // residential
      residentials: [],
      // build
      builds: [],
      filterData: {
        type: "taswiq_eqara",
        area_id: "",
        goverment_id: "",
        city_id: "",
        residential_id: "",
        building_id: "",
        rent_type: "",
      },
    };
  },
  mounted() {
    this.getAreas();
  },
  methods: {
    // areas
    getAreas() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/getarea", {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.areas = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    // goverment
    getGoverment() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL + "/getgoverment?area_id=" + this.filterData.area_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.goverments = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // city
    getCity() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL +
            "/getcity?goverment_id=" +
            this.filterData.goverment_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.cities = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // residential
    getResidential() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL + "/getresidential?city_id=" + this.filterData.city_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.residentials = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // builds
    getBuilds() {
      this.loadingSelect = true;
      axios
        .get(
          this.baseURL +
            "/getbuilding?residential_id=" +
            this.filterData.residential_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSelect = false;
          this.builds = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingSelect = false));
    },
    // send filter data
    sendFilter() {
      localStorage.setItem("type", this.filterData.type);
      localStorage.setItem("area_id", this.filterData.area_id);
      localStorage.setItem("goverment_id", this.filterData.goverment_id);
      localStorage.setItem("city_id", this.filterData.city_id);
      localStorage.setItem("residential_id", this.filterData.residential_id);
      localStorage.setItem("building_id", this.filterData.building_id);
      if (this.filterData.rent_type == "") {
        localStorage.setItem("rent_type", "sale");
      } else {
        localStorage.setItem("rent_type", this.filterData.rent_type);
      }
      this.$router.push({ name: "search" });
    },
  },
  components: {
    Load,
    Multiselect,
  },
};
</script>
