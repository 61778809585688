<template>
  <div class="main">
    <!-- search box -->
    <div class="search-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="input-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="اكتب كلمة البحث"
                v-model="searchText"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="Search"
                v-model="searchText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- cards -->
    <div class="all-sections">
      <ul class="nav mb-3" v-if="filter.type === 'taswiq_eqara'">
        <li class="nav-item">
          <button
            type="button"
            class="nav-link"
            v-bind:class="{ active: currentFilter === 'sale' }"
            v-on:click="setFilter('sale')"
          >
            <span class="ar"> قسم البيع </span>
            <span class="en"> Sales department </span>
          </button>
        </li>
        <li class="nav-item">
          <button
            type="button"
            class="nav-link"
            v-bind:class="{ active: currentFilter === 'rent' }"
            v-on:click="setFilter('rent')"
          >
            <span class="ar">قسم التأجير</span>
            <span class="en">Rent department</span>
          </button>
        </li>
      </ul>
      <Load v-if="loading"></Load>
      <div class="container" v-else>
        <div class="row">
          <div class="col-md-12 ar" v-if="cards == ''">
            <div class="not-found">
              <span><i class="fas fa-exclamation-triangle"></i> </span>
              <h3>عفوا لا يوجد اعلانات</h3>
            </div>
          </div>
          <div class="col-md-12 en" v-if="cards == ''">
            <div class="not-found">
              <span><i class="fas fa-exclamation-triangle"></i> </span>
              <h3>Sorry, there are no ads</h3>
            </div>
          </div>
          <div class="col-md-12" v-else>
            <router-link
              :to="'/datails/' + card.id"
              tag="a"
              v-for="card in filteredSearch"
              :key="card.id"
              class="offer-color"
            >
              <div class="offer-section">
                <div class="offer-details">
                  <h5>{{ card.subject }}</h5>
                  <div class="flex-div">
                    <div class="address-det">
                      <span class="icon"
                        ><i class="fas fa-map-marker-alt"></i
                      ></span>
                      <span> {{ card.area }} </span>
                    </div>
                    <div class="post-date">
                      <span class="ar">
                        منذ
                        {{
                        
                          card.created_at.date| moment("from", "now", true) 
                      }}
                      </span>
                      <span class="en">
                       
                        {{
                        
                          card.created_at.date| moment("from", "now", true) 
                      }}
                       ago
                      </span>
                    </div>
                  </div>
                  <div class="flex-div">
                    <div class="owner-name">
                      <span class="icon"><i class="fas fa-user"></i></span>
                      <span> {{ card.user }}</span>
                    </div>
                    <div class="owner-num">
                      <span>{{ card.id }}</span>
                    </div>
                  </div>
                </div>
                <div class="offer-image" v-if="card.image !== null">
                  <div class="image">
                    <img :src="card.image" />
                  </div>
                </div>
                <div class="offer-image" v-else>
                  <div class="image">
                    <img
                      src="../../assets/images/icons/hidden-image.png"
                      class="load-image"
                    />
                  </div>
                </div>
                <div class="offer-type" v-if="card.typeadvertising !== null">
                  <img :src="card.typeadvertising.icon" />
                </div>
              </div>
              <!--offer-section-->
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  name: "Search",
  data() {
    return {
      baseURL: "http://mawjoud.sa/mogood/public/api",
      loading: false,
      cards: [],
      searchText: "",
      currentFilter: "sale",
      filter: {
        type: localStorage.getItem("type"),
        area_id: localStorage.getItem("area_id"),
        goverment_id: localStorage.getItem("goverment_id"),
        city_id: localStorage.getItem("city_id"),
        residential_id: localStorage.getItem("residential_id"),
        rent_type: localStorage.getItem("rent_type"),
        building_id: localStorage.getItem("building_id"),
        service_id: localStorage.getItem("service_id"),
      },
    };
  },
  mounted() {
    this.searchResult();
  },
  methods: {
    searchResult() {
      // loading
      this.loading = true;
      axios
        .get(
          this.baseURL +
            "/filter?" +
            "type=" +
            this.filter.type +
            "&" +
            "area_id=" +
            this.filter.area_id +
            "&" +
            "goverment_id=" +
            this.filter.goverment_id +
            "&" +
            "city_id=" +
            this.filter.city_id +
            "&" +
            "residential_id=" +
            this.filter.residential_id +
            "&" +
            "building_id=" +
            this.filter.building_id +
            "&" +
            "rent_type=" +
            this.filter.rent_type +
            "&" +
            "service=" +
            this.filter.service_id,
          {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          this.loading = false;
          this.cards = res.data.data;
          // filter tabs
          this.currentFilter = localStorage.getItem("rent_type");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    // filter tabs
    setFilter: function(filter) {
      this.filter.rent_type = filter;
      localStorage.setItem("rent_type", filter);
      this.currentFilter = localStorage.getItem("rent_type");
      this.searchResult();
    },
  },
  computed: {
    filteredSearch: function() {
      return this.cards.filter((card) => {
        return card.subject.toLowerCase().match(this.searchText);
      });
    },
  },
  components: {
    Load,
  },
};
</script>
