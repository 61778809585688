<template>
  <div class="main">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="gray-green">
              <span class="ar">تواصل معنا</span>
              <span class="en">contact us</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-us">
      <Load v-if="loading" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="have-account" style="margin-top: -20px">
              <span class="ar">أهلا بك أخي الكريم</span>
              <span class="en">Welcome </span>
            </div>
            <div class="have-account" style="margin-top: -20px">
              <span class="ar">و نسعد بالتواصل معك</span>
              <span class="en">We are happy for contact with us </span>
            </div>
            <div class="my-form">
              <form>
                <!-- <div class="form-group">
                  <input
                    type="text"
                    class="form-control ar"
                    placeholder="ادخل اسمك"
                    v-model="contact.name"
                  />
                  <input
                    type="text"
                    class="form-control en"
                    placeholder="enter your name"
                    v-model="contact.name"
                  />
                  <span class="icon"> <i class="fas fa-user"></i> </span>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control ar"
                    placeholder="رقم الجوال"
                    v-model="contact.phone"
                  />
                   <input
                    type="text"
                    class="form-control en"
                    placeholder="phone number"
                    v-model="contact.phone"
                  />
                  <span class="icon"> <i class="fas fa-phone"></i> </span>
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control ar"
                    placeholder="البريد الالكتروني"
                    v-model="contact.email"
                  />
                  <input
                    type="email"
                    class="form-control en"
                    placeholder="email"
                    v-model="contact.email"
                  />
                  <span class="icon"><i class="fas fa-envelope"></i></span>
                </div> -->
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control ar"
                    placeholder="عنوان الرسالة"
                    v-model="contact.subject"
                    @focus="errorShow = false"
                  />
                  <input
                    type="text"
                    class="form-control en"
                    placeholder="message title"
                    v-model="contact.subject"
                    @focus="errorShow = false"
                  />
                  <span class="icon"><i class="far fa-comment"></i></span>
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control ar"
                    placeholder="الرسالة..."
                    v-model="contact.message"
                    @focus="errorShow = false"
                  ></textarea>
                  <textarea
                    class="form-control en"
                    placeholder="Message ...."
                    v-model="contact.message"
                    @focus="errorShow = false"
                  ></textarea>
                </div>
                <button
                  type="button"
                  class="btn form-btn btn-block"
                  @click="contactUs()"
                >
                  <span class="ar"> ارسال </span>
                  <span class="en"> send </span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  name: "contact",
  data() {
    return {
      contact: {
        // name: "",
        // email: "",
        // phone: "",
        subject: "",
        message: "",
      },
      loading: false,
      errorMessage: "",
      errorShow: false,
      errorColor: "",
      baseURL: "http://mawjoud.sa/mogood/public/api",
    };
  },
  methods: {
    contactUs() {
      // loading
      this.loading = true;
      if (this.contact.subject == "") {
        setTimeout(() => {
          this.loading = false;
          this.errorColor = "faild";
          this.errorShow = true;
          this.errorMessage = "يرجي إدخال عنوان الرسالة";
        }, 2000);
      } else if (this.contact.message == "") {
        setTimeout(() => {
          this.loading = false;
          this.errorColor = "faild";
          this.errorShow = true;
          this.errorMessage = "يرجي إدخال نص الرسالة";
        }, 2000);
      } else {
        axios
        .post(this.baseURL + "/contactus", this.contact, {
          headers: {
            lang: localStorage.getItem("lang"),
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.status == 200) {
            this.errorColor = "success";
            this.errorShow = true;
            this.errorMessage = "تم إرسال الرسالة بنجاح"
          }
        })
        .catch((error) => {
          this.errorColor = "faild";
            this.errorShow = true;
            this.errorMessage = error.response.data.message;
        })
        .finally(() => (this.loading = false));
      }
    },
  },
  components: {
    Load,
  },
};
</script>
