<template>
  <div class="data pin-show">
    <!-- title page -->
    <!-- <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="gray-green">
              <span class="ar">تثبيت الاعلان</span>
              <span class="en"> Pin Show </span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="site-treaty">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="pin-show" v-if="showPayForm">
              <p class="ar">
                التثبيت يجعل اعلانك في الصدارة على مدار العام ويساهم في الوصول
                لاكبر عدد من العملاء ويعطيك الاحقية في احتكار الباقة
              </p>
              <p class="en">
                Pinning puts your ad on top all year round and contributes to
                reach To the largest number of customers and gives you the right
                to monopolize the package
              </p>
            </div>
            <div class="com-table ar pinn" v-if="showPayForm">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>نوع الباقة</th>
                    <th>المزايا</th>
                    <th>المدة</th>
                    <th>السعر r.s</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in packages" :key="item.id">
                    <td>
                      <div class="ckbox">
                        <input
                          type="radio"
                          v-model="allData.package"
                          :id="'radioo' + item.id"
                          name="p"
                          :value="item"
                        />
                        <label :for="'radioo' + item.id"> </label>
                      </div>
                    </td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.days_num }} يوم</td>
                    <td>{{ item.price / 100 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <form v-if="showPayForm">
              <div class="treaty">
                <button
                  @click="pay()"
                  id="paay"
                  type="button"
                  class="btn my-btn ar"
                >
                  دفع
                </button>
                <button
                  @click="pay()"
                  id="paay"
                  type="button"
                  class="btn my-btn en"
                >
                  Pay
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="error-message error-message-new ar" v-if="errorShow">
        <span @click="dissmiss" class="icon"><i class="fas fa-times"></i></span>
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="error-message error-message-new en" v-if="errorShow">
        <span @click="dissmiss" class="icon"><i class="fas fa-times"></i></span>
        <p :class="errorColor">
          {{ errorMessageEn }}
        </p>
      </div>
    </transition>
  </div>
</template>
<script src="https://polyfill.io/v3/polyfill.min.js?features=fetch"></script>
<script src="https://cdn.moyasar.com/mpf/1.4.0/moyasar.js"></script>
<script>
import axios from "axios";
export default {
  data() {
    return {
      errorShow: false,
      show: false,
      errorColor: "",
      errorMessage: "",
      loaded: false,
      packages: "",
      baseURL: "http://mawjoud.sa/mogood/public/api",
      allData: {
        package: "",
      },
      showPayForm: true,
      callback_url: null,
    };
  },
  methods: {
    getPackage() {
      axios
        .get(this.baseURL + "/typeadvertising", {
          headers: {
            lang: localStorage.getItem("lang"),
            // Authorization: "bearer" + localStorage.getItem("token"),
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.packages = res.data.data;
        });
    },
    pay() {
      if (this.allData.package == "") {
        this.errorShow = true;
        this.show = true;
        this.errorColor = "faild";
        this.errorMessage = "يرجى اختيار باقة من الباقات المتاحة";
        this.errorMessageEn = "Sorry, you must choose one of available package";
        this.loaded = false;
      } else {
        this.$emit("hideModel");
        this.$emit("pay");
        localStorage.setItem("packageId", this.allData.package.id);
        var myDiv = document.querySelector(".moyaser-pop");
        myDiv.classList.add("show");
        document.querySelector(".moy-over").onclick = function () {
          document.querySelector(".moyaser-pop").classList.toggle("show");
        };
        Moyasar.init({
          element: ".mysr-form",
          amount: this.allData.package.price * 100,
          currency: "SAR",
          description: this.allData.package.type,
          publishable_api_key:
            "pk_test_BEArai9bYYe7oTQyBT5vGkXxq78e8SWqGgHE6SgY",
          callback_url: this.callback_url,
          methods: ["creditcard"],
          language: localStorage.getItem("lang"),
        });
      }
    },
    dissmiss() {
      this.errorShow = false;
    },
    urlChange() {
      var site =
        this.iframData.src + "?toolbar=0&amp;navpanes=0&amp;scrollbar=0";
      document.getElementById("iFrameName").src = site;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.callback_url = `http://mawjoud.sa/thank-you?ad_id=${this.$route.params.id}`;
    } else {
      this.callback_url = "http://mawjoud.sa/thank-you";
    }
    this.getPackage();
  },
};
</script>
