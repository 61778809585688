import Vue from 'vue';
import Router from 'vue-router'
import Home from './../components/home';
// home page
import MainHome from './../components/homa-pages/main-home'
import TaswiqEqara from './../components/homa-pages/taswiq_eqara';
import BinaWataemir from './../components/homa-pages/bina_wataemir';
import Sianuh from './../components/homa-pages/sianuh';
import Weddings from './../components/homa-pages/weddings';
// search page
import Search from './../components/search/search';
// card details
import CardDetails from './../components/card/card-details';
// user
import Login from './../components/user/login';
import Register from './../components/user/register';
import ForgetPasswordPhone from './../components/user/forget-password-phone';
import ForgetPasswordCode from './../components/user/forget-password-code';
import NewPassword from './../components/user/new-password';
import Favourite from './../components/user/fav';
import Notifi from './../components/user/notification';
import Setting from './../components/user/settings';
import UpdateProfile from './../components/user/update-profile';
import UpdatePassword from './../components/user/update-password';
import AddShow from './../components/show/add-show';
import MyAds from './../components/user/my-ads';
import PinShow from './../components/show/pin-show.vue';
import ThankYou from './../components/show/thank-you';
import EditAds from './../components/show/update-show'
// static pages
import About from './../components/static-pages/about';
import Policy from './../components/static-pages/policy';
import Terms from './../components/static-pages/terms';
import Contact from './../components/static-pages/contact';
import Commission from './../components/static-pages/commission';
import Treaty from './../components/static-pages/site-treaty';
import Report from './../components/static-pages/report';
// chat
import Chat from './../components/chat/all_chat';
import ChatDetails from './../components/chat/chat-details';
Vue.use(Router);
const routes = [{
        path: "/",
        name: 'home',
        component: Home,
        children: [{
                path: "/",
                name: 'mainhome',
                component: MainHome
            },
            // home page
            {
                path: "/taswiq_eqara",
                name: 'taswiq_eqara',
                component: TaswiqEqara
            },
            {
                path: "/sianuh/:id",
                name: 'sianuh',
                component: Sianuh
            },
            {
                path: "/bina_wataemir/:id",
                name: 'bina_wataemir',
                component: BinaWataemir
            },
            {
                path: "/weddings/:id",
                name: 'weddings',
                component: Weddings
            },
            // search page
            {
                path: "/search",
                name: 'search',
                component: Search
            },
            // card details
            {
                path: '/datails/:id',
                name: 'cardDetails',
                component: CardDetails,
            },
            // user
            {
                path: '/login',
                name: 'login',
                component: Login,
            },
            {
                path: '/forget-enter-phone',
                name: "forget-enter-phone",
                component: ForgetPasswordPhone,
            },
            {
                path: '/forget-enter-code',
                name: "forget-enter-code",
                component: ForgetPasswordCode,
            },
            {
                path: '/new-password',
                name: "new-password",
                component: NewPassword,
            },
            {
                path: '/register',
                name: 'register',
                component: Register,
            },
            {
                path: '/favourite',
                name: 'favourite',
                component: Favourite,
            },
            {
                path: '/notification',
                name: 'notification',
                component: Notifi,
            },
            {
                path: '/setting',
                name: 'setting',
                component: Setting,
            },
            {
                path: '/editProfile',
                name: 'editProfile',
                component: UpdateProfile,
            },
            {
                path: '/update-password',
                name: 'update-password',
                component: UpdatePassword,
            },
            {
                path: '/add-show',
                name: 'add-show',
                component: AddShow,
            },
            {
                path: '/pin-show/:id',
                name: 'pin-show',
                component: PinShow,
            },
            {
                path: '/thank-you',
                name: 'ThankYou',
                component: ThankYou,
            },
            {
                path: '/myAds',
                name: 'myAds',
                component: MyAds,
            },
            {
                path: '/edit/:id',
                name: 'editAds',
                component: EditAds,
            },

            // static pages
            {
                path: "/about",
                name: 'about',
                component: About
            },
            {
                path: "/usage-policy",
                name: 'usage-policy',
                component: Policy
            },
            {
                path: "/terms",
                name: 'terms',
                component: Terms
            },
            {
                path: "/contact",
                name: 'contact',
                component: Contact
            },
            {
                path: "/commission",
                name: 'commission',
                component: Commission
            },
            {
                path: "/site-treaty",
                name: 'site-treaty',
                component: Treaty
            },
            {
                path: "/:id/report",
                name: 'report',
                component: Report
            },
            // chat
            {
                path: '/chat',
                name: 'chat',
                component: Chat,
            },
            {
                path: '/chat/:id',
                name: 'chatDetails',
                component: ChatDetails,
            },
        ]
    },
    {
        path: '*',
        redirect: '/'
    }
]


export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})