<template>
  <div class="main">
    <!-- title -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <p class="ar">المفضلة</p>
              <p class="en">Favourite</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Load v-if="loading"></Load>
    <!--start favourite-->
    <div class="favourite" v-else>
      <div class="container">
        <div class="not-found" v-if="cards == ''">
          <span><i class="fas fa-exclamation-triangle"></i> </span>
          <h3 class="ar">لا يوجد اعلانات مفضلة</h3>
          <h3 class="en">There are no favorite ads</h3>
        </div>
        <div class="favourite-card" v-else>
          <Load v-if="loadingDelete" class="load-form"></Load>
          <div
            v-for="card in cards"
            :key="card.advertising_id"
            class="offer-section fadeInUp"
          >
            <div class="offer-details">
              <router-link :to="'/datails/' + card.advertising_id" tag="a">
                <h5>{{ card.subject }}</h5>
              </router-link>
              <div class="flex-div">
                <div class="address-det">
                  <span class="icon">
                    <i class="fas fa-map-marker-alt"></i
                  ></span>
                  <span> {{ card.area }} </span>
                </div>
                <div class="post-date">
                      <span class="ar">
                        منذ
                        {{
                        
                          card.created_at.date| moment("from", "now", true) 
                      }}
                      </span>
                      <span class="en">
                        {{
                        
                          card.created_at.date| moment("from", "now", true) 
                      }}
                      ago
                      </span>
                </div>
              </div>
              <div class="flex-div">
                <div class="owner-name">
                  <span class="icon"><i class="fas fa-user"></i></span>
                  <span> {{ card.user }} </span>
                </div>
                <div class="owner-num">
                  <span>{{ card.advertising_id }} </span>
                </div>
              </div>
            </div>
            <div class="offer-image">
              <div class="image">
                <img :src="card.image" />
              </div>
            </div>

            <div class="fav-icon">
              <button
                type="button"
                class="fav active"
                @click="likeMethod(card.advertising_id)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  name: "Favourite",
  data() {
    return {
      baseURL: "http://mawjoud.sa/mogood/public/api",
      loading: false,
      loadingDelete: false,
      cards: [],
    };
  },

  methods: {
    favouriteCard() {
      // loading
      this.loading = true;
      axios
        .get(this.baseURL + "/favourit", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          this.cards = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    likeMethod(advertising_id) {
      this.loadingDelete= true,
      axios
        .post(
          this.baseURL + "/addfavourit",
          { advertising_id: advertising_id },
          {
            headers: {
              Authorization: "bearer" + localStorage.getItem("token"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then(() => {
          this.loadingDelete = false
          this.favouriteCard();
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => (this.loadingDelete = false));
    },
  },
  mounted() {
    this.favouriteCard();
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  components: {
    Load,
  },
};
</script>
