<template>
  <div class="main">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="gray-green">
              <span class="ar">ابلاغ</span>
              <span class="en">Report</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-us">
      <Load v-if="loading" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="my-form">
              <form>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control ar"
                    placeholder="عنوان المخالفة"
                    v-model="contact.subject"
                    @focus="errorShow = false"
                  />
                  <input
                    type="text"
                    class="form-control en"
                    placeholder="Report Subject"
                    v-model="contact.subject"
                    @focus="errorShow = false"
                  />
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control ar"
                    placeholder="تفضل بشرح المخالفة..."
                    v-model="contact.message"
                    @focus="errorShow = false"
                  ></textarea>
                  <textarea
                    class="form-control en"
                    placeholder="Please Explain Report"
                    v-model="contact.message"
                    @focus="errorShow = false"
                  ></textarea>
                </div>
                <button
                  type="button"
                  class="btn form-btn btn-block"
                  @click="contactUs()"
                >
                  <span class="ar"> ارسال للمشرف </span>
                  <span class="en"> send To Supervisior </span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  name: "contact",
  data() {
    return {
      contact: {
        subject: "",
        message: "",
        advertising_id: this.$route.params.id,
      },
      loading: false,
      errorMessage: "",
      errorShow: false,
      errorColor: "",
      baseURL: "http://mawjoud.sa/mogood/public/api",
    };
  },
  methods: {
    contactUs() {
      this.loading = true;
      if (this.contact.subject == "") {
        setTimeout(() => {
          this.loading = false;
          this.errorColor = "faild";
          this.errorShow = true;
          this.errorMessage = "يرجي إدخال عنوان الشكوي";
        }, 2000);
      } else if (this.contact.message == "") {
        setTimeout(() => {
          this.loading = false;
          this.errorColor = "faild";
          this.errorShow = true;
          this.errorMessage = "يرجي إدخال نص الشكوي";
        }, 2000);
      } else {
        axios
          .post(this.baseURL + "/create/report", this.contact, {
            headers: {
              lang: localStorage.getItem("lang"),
              "Content-type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.status == 200) {
              this.errorColor = "success";
              this.errorShow = true;
              this.errorMessage = "تم إرسال الشكوي بنجاح";
            }
          })
          .catch((error) => {
            this.errorColor = "faild";
            this.errorShow = true;
            this.errorMessage = error.response.data.message;
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  components: {
    Load,
  },
};
</script>
