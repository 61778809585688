<template>
  <div class="data">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <span class="ar"> تعديل بياناتي</span>
              <span class="en"> Update Profile</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <div class="login">
      <Load v-if="loading" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="container">
        <div class="my-form">
          <form>
            <div class="form-group">
              <div class="upload-block">
                <div class="upload_wrap">
                  <img :src="user.imgSrc" class="uploading-image" />
                  <input type="file" accept="image/*" @change="uploadImage" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="اسم المستخدم"
                v-model="user.name"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="user name"
                v-model="user.name"
              />
              <span class="icon"><i class="fas fa-user"></i></span>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="رقم الجوال"
                v-model="user.phone"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="phone number"
                v-model="user.phone"
              />
              <span class="icon"><i class="fas fa-phone"></i></span>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="البريد الإلكتروني"
                v-model="user.email"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="email"
                v-model="user.email"
              />
              <span class="icon"><i class="fas fa-envelope"></i></span>
            </div>
            <button
              type="button"
              class="btn form-btn btn-block"
              @click="updateNow()"
            >
              <span class="ar"> تعديل </span>
              <span class="en"> Update </span>
            </button>
          </form>
          <div class="have-account">
            <span class="ar">
              <router-link tag="a" to="/update-password"
                >تعديل كلمة المرور</router-link
              >
            </span>
            <span class="en">
              <router-link tag="a" to="/update-password">
                Update password</router-link
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  data() {
    return {
      loading: false,
      errorMessage: "",
      errorShow: false,
      errorColor: "",
      baseURL: "http://mawjoud.sa/mogood/public/api",
      user: {
        _method: "PUT",
        image: null,
        imgSrc: null,
        name: "",
        phone: "",
        email: "",
      },
    };
  },
  methods: {
    uploadImage(event) {
      this.user.image = event.target.files[0];
      this.user.imgSrc = URL.createObjectURL(this.user.image);
    },
    getDate() {
      this.loading = true;
      axios
        .get(this.baseURL + "/user", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          console.log(res.data.user)
          this.user.name = res.data.user.name;
          this.user.phone = res.data.user.phone;
          this.user.email = res.data.user.email;
          this.user.imgSrc = res.data.user.image;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    updateNow() {
      this.loading = true;
      const data = new FormData();
      data.append("_method", this.user._method);
      if (this.user.image !== null) {
       data.append("image", this.user.image);
      }
      data.append("name", this.user.name);
      data.append("phone", this.user.phone);
      data.append("email", this.user.email);
      axios
        .post(this.baseURL + "/update", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.status == 200) {
            this.errorColor = "success";
            this.errorShow = true;
            this.errorMessage = "تم تعديل بياناتك بنجاح";
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response);
          if (error.response.status == 422) {
            this.errorColor = "faild";
            this.errorShow = true;
            this.errorMessage = error.response.data.message;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getDate();
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  name: "editProfile",
  components: {
    Load,
  },
};
</script>
