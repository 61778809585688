<template>
  <div class="data">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray">
              <span class="ar"> تعديل بياناتي</span>
              <span class="en"> Update Profile</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <div class="login">
      <Load v-if="loading" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="error-message" v-if="errorpasswordShow">
        <p :class="errorColor">
          {{ errorpassword_confirmation }}
        </p>
      </div>
      <div class="container">
        <div class="my-form">
          <form method="get" action="index.html">
            <div class="form-group">
              <input
                type="password"
                class="form-control ar"
                placeholder="كلمة المرور القديمة"
                v-model="user.old_password"
              />
              <input
                type="password"
                class="form-control en"
                placeholder="old password"
                v-model="user.old_password"
              />
              <span class="icon"><i class="fas fa-lock"></i></span>
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control ar"
                placeholder="كلمة المرور الجديدة"
                v-model="user.new_password"
              />
              <input
                type="password"
                class="form-control en"
                placeholder="new password"
                v-model="user.new_password"
              />
              <span class="icon"><i class="fas fa-lock"></i></span>
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control ar"
                placeholder="تأكيد كلمة المرور الجديدة"
                v-model="user.confirm_password"
              />
              <input
                type="password"
                class="form-control en"
                placeholder="confirm new password"
                v-model="user.confirm_password"
              />
              <span class="icon"><i class="fas fa-lock"></i></span>
            </div>
            <button
              type="button"
              class="btn form-btn btn-block"
              @click="updateNow()"
            >
              <span class="ar"> تعديل </span>
              <span class="en"> Update </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Load from "./../loading";
export default {
  data() {
    return {
      loading: false,
      errorMessage: "",
      errorpassword_confirmation: "",
      errorShow: false,
      errorpasswordShow: false,
      errorColor: "",
      baseURL: "http://mawjoud.sa/mogood/public/api",
      user: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    updateNow() {
      this.loading = true;
      if (this.user.new_password !== this.user.confirm_password) {
        this.loading = false;
        this.errorColor = "faild";
        this.errorpasswordShow = true;
        this.errorpassword_confirmation =
          "الرقم السري و تأكيد الرقم السري مختلفين";
      } else {
        this.errorpasswordShow = false;
        axios
          .post(this.baseURL + "/change-password", this.user, {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
              lang: localStorage.getItem("lang"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.status == 200) {
              this.errorColor = "success";
              this.errorShow = true;
              this.errorMessage = "تم تعديل كلمة المرور بنجاح";
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response);
            if (error.response.status == 422) {
              this.errorColor = "faild";
              this.errorShow = true;
              this.errorMessage = error.response.data.errors;
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  name: "update-password",
  components: {
    Load,
  },
};
</script>
