<template>
  <div class="main all_ser">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="site-sections">
      <div class="container">
        <ul class="list-unstyled">
          <li>
            <router-link to="/taswiq_eqara" tag="a">
              <span class="ar"> موجـود لتسويق العقار </span>
              <span class="en"> Mawgoud to market the property </span>
            </router-link>
          </li>
          <li>
            <router-link to="/bina_wataemir/2" tag="a">
              <span class="ar"> موجـود للبناء والتعمير </span>
              <span class="en">
                Mawgoud for construction and reconstruction
              </span>
            </router-link>
          </li>
          <li>
            <router-link to="/sianuh/3" tag="a">
              <span class="ar">موجـود للخدمات المنزلية </span>
              <span class="en"> Mawgoud for home services </span>
            </router-link>
          </li>
          <li>
            <router-link to="/weddings/8" tag="a">
              <span class="ar"> موجـود للافراح والمناسبات</span>
              <span class="en"> Mawgoud for weddings and events</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
