<template>
  <div class="data">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="gray-green">
              <span class="ar">معاهدة الموقع</span>
              <span class="en"> Site Treaty </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="site-treaty">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="commission">
              <h4 class="ar">قيمة العمولة</h4>
              <h4 class="en">Commission Value</h4>
            </div>
            <div class="com-table ar">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>صفة المعلن</td>
                    <td>قيمة العمولة</td>
                  </tr>
                  <tr>
                    <td>مكاتب عقارية</td>
                    <td>نسبة 25% من مبلغ السعي الذي تيسر الحصول عليه</td>
                  </tr>
                  <tr>
                    <td>الافراد</td>
                    <td>نسبة 1% من قيمة العقار او مبلغ الايجار</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="com-table en">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>Advertiser page</td>
                    <td>Commission value</td>
                  </tr>
                  <tr>
                    <td>Real estate offices</td>
                    <td>
                      25% of the amount of the pursuit that can be obtained
                    </td>
                  </tr>
                  <tr>
                    <td>Individuals</td>
                    <td>1% of the property value or the rental amount</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <form>
              <div class="treaty">
                <div class="quran ar">
                  <p>
                    قال تعالى (ولا تنقضوا الايمان بعد توكيدها وقد جعلتم الله
                    عليكم كفيلا)
                  </p>
                </div>
                <div class="quran en">
                  <p>
                    (and break not your oaths after ye have confirmed them;
                    indeed ye have made Allah your surety; for Allah knoweth all
                    that ye do.)
                  </p>
                </div>

                <ul class="list-inline ar">
                  <li class="ckbox">
                    <input
                      @change="!allData.check1"
                      type="checkbox"
                      v-model="allData.check1"
                      id="radio"
                      name="d"
                    />
                    <label for="radio"
                      >أتعهد أنا المعلن وأقسم بالله العظيم بدفع عمولة الموقع
                      الموضحة أعلاه في حال تمت عملية بيع العقار أو الإيجار عن
                      طريق الموقع والله على ما أقول شهيد
                    </label>
                  </li>
                  <li class="ckbox">
                    <input
                      @change="!allData.check2"
                      type="checkbox"
                      v-model="allData.check2"
                      id="radio2"
                      name="d"
                    />
                    <label for="radio2"
                      >اتعهد بدفع العمولة في مدة اقصاها اسبوع من تاريخ البيع او
                      الايجار</label
                    >
                  </li>
                  <!-- <li class="ckbox" v-if="userBackage == false">
                    <input
                      @change="!allData.check3"
                      type="checkbox"
                      v-model="allData.check3"
                      id="radio3"
                      name="d"
                    />
                    <label for="radio3">هل تريد تثبيت إعلاناتك ؟</label>
                  </li> -->
                </ul>
                <ul class="list-inline en">
                  <li class="ckbox">
                    <input
                      @change="!allData.check1"
                      type="checkbox"
                      v-model="allData.check1"
                      id="radio"
                      name="d"
                    />
                    <label for="radio"
                      >I pledge before God to pay the application commission
                      described above in the event that the sale of the property
                      is carried out through the application, and God is what I
                      say is a martyr</label
                    >
                  </li>
                  <li class="ckbox">
                    <input
                      @change="!allData.check2"
                      type="checkbox"
                      v-model="allData.check2"
                      id="radio2"
                      name="d"
                    />
                    <label for="radio2"
                      >I pledge to pay the commission within a maximum period of
                      one week from the date of sale or rent</label
                    >
                  </li>
                  <!-- <li class="ckbox" v-if="userBackage == false">
                    <input
                      @change="!allData.check3"
                      type="checkbox"
                      v-model="allData.check3"
                      id="radio3"
                      name="d"
                    />
                    <label for="radio3">Do you want pend this ads ?</label>
                  </li> -->
                </ul>
                <!-- <PinShow @pay="pay" v-if="allData.check3"></PinShow> -->
                <button
                  @click="agreeTreaty()"
                  type="button"
                  class="btn my-btn ar"
                >
                  استمر لاضافة الاعلان
                </button>
                <button
                  @click="agreeTreaty()"
                  type="button"
                  class="btn my-btn en"
                >
                  Continue Add Advertisment
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="error-message error-message-new ar" v-if="errorShow">
        <span @click="dissmiss" class="icon"><i class="fas fa-times"></i></span>
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="error-message error-message-new en" v-if="errorShow">
        <span @click="dissmiss" class="icon"><i class="fas fa-times"></i></span>
        <p :class="errorColor">
          {{ errorMessageEn }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
// import PinShow from "../show/pin-show.vue";
export default {
  data() {
    return {
      errorShow: false,
      show: false,
      errorColor: "",
      errorMessage: "",
      baseURL: "http://mawjoud.sa/mogood/public/api",
      allData: {
        check1: false,
        check2: false,
        check3: false,
      },
      userBackage: false,
    };
  },
  methods: {
    agreeTreaty() {
      if (this.allData.check1 === true && this.allData.check2 === true) {
        localStorage.setItem("accept-conditions", "true");
        this.$router.push({ name: "add-show" });
      } else {
        this.errorShow = true;
        this.show = true;
        this.errorColor = "faild";
        this.errorMessage = "عفوا يجب ان تقوم بالموافقة على الشروط السابقة";
        this.errorMessageEn =
          "Sorry, you must agree to the previous conditions";
      }
    },
    dissmiss() {
      this.errorShow = false;
    },
    getUserData() {
      axios
        .get(this.baseURL + "/user", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("token"),
            lang: localStorage.getItem("lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          this.userBackage = res.data.user.is_packages;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getUserData();
    var token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  components: {
    // PinShow,
  },
};
</script>
