// store/index.js

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        transaction_id: "",
    },
    getters: {
        getId(state) {
            return state.transaction_id
        },
    },
    mutations: {
        SET_TRANS_ID(state, payload) {
            state.transaction_id = payload;
        },
    },
    actions: {
        setTransId({ commit }, payload) {
            console.log(payload);
            commit('SET_TRANS_ID', payload);
        }
    }
});