<template>
  <div class="data">
    <!-- title page -->
    <div class="gray-box">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="inner-gray"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <div class="login">
      <Load v-if="loading" class="load-form"></Load>
      <div class="error-message" v-if="errorShow">
        <p :class="errorColor">
          {{ errorMessage }}
        </p>
      </div>
      <div class="container">
        <div class="have-account">
          <span class="ar">من فضلك قم بإدخال رقم الجوال الخاص بك و سيصلك رساله تحتوي علي كود تفعيل خاص بك</span>
          <span class="en">Please enter your phone number and you will recive code on mobile </span>
        </div>
        <div class="my-form">
          <form method="get" action="index.html">
            <div class="form-group">
              <input
                type="text"
                class="form-control ar"
                placeholder="رقم الجوال "
                @keyup="firstMethod"
                v-model="user.phone"
              />
              <input
                type="text"
                class="form-control en"
                placeholder="Enter your phone"
                @keyup="firstMethod"
                v-model="user.phone"
              />
              <span class="icon"><i class="fas fa-phone"></i></span>
            </div>
            <button
              type="button"
              class="btn form-btn btn-block"
              @click="forgetPassword()"
            >
              <span class="ar"> متابعة </span>
              <span class="en"> Next </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Load from "./../loading";
import axios from "axios";
var persianNumbers = [
  /۰/g,
  /۱/g,
  /۲/g,
  /۳/g,
  /۴/g,
  /۵/g,
  /۶/g,
  /۷/g,
  /۸/g,
  /۹/g,
];
var arabicNumbers = [
  /٠/g,
  /١/g,
  /٢/g,
  /٣/g,
  /٤/g,
  /٥/g,
  /٦/g,
  /٧/g,
  /٨/g,
  /٩/g,
];
export default {
  data() {
    return {
      loading: false,
      errorMessage: "",
      errorShow: false,
      errorColor: "",
      baseURL: "http://mawjoud.sa/mogood/public/api",
      user: {
        phone: "",
      },
    };
  },
  methods: {
    forgetPassword() {
      this.loading = true;
        axios
          .post(this.baseURL + "/forgot-password", this.user, {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
              lang: localStorage.getItem("lang"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.status == 200) {
              this.$router.push({ name: "forget-enter-code" });
              localStorage.setItem("validate-phone", this.user.phone)
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response);
            if (error.response.status == 400) {
              this.errorColor = "faild";
              this.errorShow = true;
              this.errorMessage = error.response.data.message;
            }
          })
          .finally(() => (this.loading = false));
        
    },
    firstMethod() {
      if (typeof this.user.phone === "string") {
        for (var i = 0; i < 10; i++) {
          this.user.phone = this.user.phone
            .replace(persianNumbers[i], i)
            .replace(arabicNumbers[i], i);
        }
      }
      return (this.user.phone);
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    if (token) {
      this.$router.push({ name: "mainhome" });
    }
  },
  name: "login",
  created() {
    this.firstMethod();
  },
  components: {
    Load,
  },
};
</script>
